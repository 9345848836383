import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CourseHeader from "../../components/Courses/CourseHeader";
import pythonDef from '../../assets/courses/html.json';
import CourseIntro from "../../components/Courses/CourseIntro";
import { HashLink } from 'react-router-hash-link';
import WhatAndWhyHtml from "./HtmlLessons/WhatAndWhyHtml";
import BasicTags from "./HtmlLessons/BasicTags";
import CommentsStyleAndColor from "./HtmlLessons/CommentsStyleAndColor";
import Links from "./HtmlLessons/Links";
import Lists from "./HtmlLessons/Lists";
import Tables from "./HtmlLessons/Tables";
import Images from "./HtmlLessons/Images";
import Videos from "./HtmlLessons/Videos";
import DivsSpansAndMetaTags from "./HtmlLessons/DivsSpansAndMetaTags";
import Inputs from "./HtmlLessons/Inputs";


const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

class Html extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseDef: pythonDef
    };
  }

  componentDidMount() {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("sidebar-collapse");
  }

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
            <CourseHeader>Html</CourseHeader>
          <div className="section section-about-us">
            <Container>
                <CourseIntro>
                    The HyperText Markup Language, or HTML is the standard markup language for documents designed
                    to be displayed in a web browser. Is the basic building block of most of the websites we browse
                    every day. Learning to use it will allow you to write your own custom website. <br/><br/>
                    The videos of the course are taken, with consent, from <a
                    href={"https://www.mikedane.com/web-development/html/"} target="_blank">Mike Dane</a>.
                     We thank him for his contribution.<br/><br/>
                    <h5>Lessons list:</h5>
                    <HashLink smooth to={"#lesson1"} scroll={scrollWithOffset}>
                        Lesson 1 - What is and why HTML
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson2"} scroll={scrollWithOffset}>
                        Lesson 2 - Basic tags
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson3"} scroll={scrollWithOffset}>
                        Lesson 3 - Comments, style and color
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson4"} scroll={scrollWithOffset}>
                        Lesson 4 - Links
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson5"} scroll={scrollWithOffset}>
                        Lesson 5 - Images
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson6"} scroll={scrollWithOffset}>
                        Lesson 6 - Videos
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson7"} scroll={scrollWithOffset}>
                        Lesson 7 - Lists
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson8"} scroll={scrollWithOffset}>
                        Lesson 8 - Tables
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson9"} scroll={scrollWithOffset}>
                        Lesson 9 - Divs and Spans, Meta tags
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson10"} scroll={scrollWithOffset}>
                        Lesson 10 - Towards JavaScript: Inputs
                    </HashLink><br/>
                </CourseIntro>
                <WhatAndWhyHtml lessonIndex={0}/>
                <BasicTags lessonIndex={1}/>
                <CommentsStyleAndColor lessonIndex={2}/>
                {/*    Skipped the lesson regarding the new HTML 5 tags main, header, footer etc:
                https://www.mikedane.com/web-development/html/formatting-a-page/
                I think at this point of the course it confuses more than it helps,
                it can be put at the end or skipped altogether.
                */}
                <Links lessonIndex={3}/>
                <Images lessonIndex={4}/>
                <Videos lessonIndex={5}/>
                <Lists lessonIndex={6}/>
                <Tables lessonIndex={7}/>
                <DivsSpansAndMetaTags lessonIndex={8}/>
                {/*Skipped iframes because to be understood correctly it needs an internet connection and it's not
                crucial to the course*/}
                {/*<IFrames lessonIndex={}/>*/}
                <Inputs lessonIndex={9}/>
            </Container>
          <DefaultFooter />
          </div>
        </div>
      </>
    );
  }
}

export default Html;
