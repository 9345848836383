import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class WhileAndFor extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"While and For"}
                       description={"Two approaches to creating loops in JavaScript"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"While Loops"} youtubeURL={"https://youtu.be/6fDBz8u1MkE"}
                              localVideoPath={""}
                >
                   While is a repetition structure that executes a piece of code as long as the condition is true. 
                   Here is how it is formed:
                   <CopyBlock
                        text={
                            "               while(condition) {\n"+
                            "                   code\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Another format of this condition is the do while. The only difference is that this one executes the code before
                    checking the condition.
                    <CopyBlock
                        text={
                            "               do {\n"+
                            "                   code\n"+
                            "               } while(condition);"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Here is an example that prints the numbers from 0 to 10:
                    <CopyBlock
                        text={
                            "               var counter = 0;\n"+
                            "               do {\n"+
                            "                   document.write( counter + \" \" );\n"+
                            "                   counter++;\n"+
                            "               } while(counter <= 10);"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"For Loops"} youtubeURL={"https://youtu.be/4H5363dWbK0"}
                              localVideoPath={""}
                >
                 Another way to do a repetition is the for loop. His structure is formed by four main parts: the initialization
                 that contains the declaration of the variables, the condition to be evaluated before each loop iteration, the
                 statement that occurs as long as the condition is true, and the code to be executed.
                 <CopyBlock
                        text={
                            "               for(initialization; condition; statement){\n"+
                            "                   code\n"+
                            "               }"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                 Here is the previous example using the for:
                 <CopyBlock
                        text={
                            "               for(var i=0; i<=10; i++){\n"+
                            "                   document.write( i + \" \" );\n"+
                            "               }"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                 These structures are very useful if you need to print each element of an array.
                 <CopyBlock
                        text={
                            "               var students = [\"Peter\", \"Mary\", \"Luke\", \"Anna\"];\n"+
                            "               for(var i=0; i<4; i++){\n"+
                            "                   document.write( students[i] + \" \" );\n"+
                            "               }"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default WhileAndFor;
