import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class Lists extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Lists"}
                       description={"How to group texts or others html elements"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Lists"} youtubeURL={"https://youtu.be/uGY59pKh3rU"}
                              localVideoPath={"videos/HTML/720p/11_Lists.mp4"}
                >
                    A list is a way to group texts or html elements, as links and images, and, in some cases
                    define an order to them.
                    To create the unordered list structure you use the tag <i>&lt;ul&gt;&lt;/ul&gt;</i>,
                    and the elements are created by
                    the tag <i>&lt;li&gt;&lt;/li&gt;</i>, as the exemple:
                    <CopyBlock
                        text={
                            "<ul>\n" +
                            "   <li>Lion</li>\n" +
                            "   <li>Zebra</li>\n" +
                            "   <li>Giraffe</li>\n" +
                            "</ul>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    This will be displayed as follows:
                    <hr/>
                    <ul>
                        <li>Lion</li>
                        <li>Zebra</li>
                        <li>Giraffe</li>
                    </ul>
                    <hr/>
                    To create a ordered list you can change the main tag to <i>&lt;ol&gt;&lt;/ol&gt;</i>.
                    This will display ordered numbers before each list item, as the example:
                    <CopyBlock
                        text={
                            "<ol>\n" +
                            "   <li>Lion</li>\n" +
                            "   <li>Zebra</li>\n" +
                            "   <li>Giraffe</li>\n" +
                            "</ol>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <ol>
                        <li>Lion</li>
                        <li>Zebra</li>
                        <li>Giraffe</li>
                    </ol>
                    <hr/>
                    One thing you can do, with this type of lists, is change the style of how the elements appear.
                    You do that modifying the property "type" of the main tag.
                    <CopyBlock
                        text={
                            '<ol type="a">\n' +
                            "   <li>Lion</li>\n" +
                            "   <li>Zebra</li>\n" +
                            "   <li>Giraffe</li>\n" +
                            "</ol>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <ol type="a">
                        <li>Lion</li>
                        <li>Zebra</li>
                        <li>Giraffe</li>
                    </ol>
                    <hr/>
                    This type can be switched to get diferent results, like: "a" (lowercase letters), "A" (uppercase letters),
                    "I" (uppercase roman numbers) and "i" (lowercase roman numbers).<br/>
                    <br/>
                    Lists can also be nested one inside the other, with multiple levels of indentations.
                    <CopyBlock
                        text={
                            '<ol type="a">\n' +
                            "   <li>Lion</li>\n" +
                            "   <ol>\n" +
                            "       <li>Baby lion</li>\n" +
                            "       <li>Grown up lion</li>\n" +
                            "   </ol>\n" +
                            "   <li>Zebra</li>\n" +
                            "   <li>Giraffe</li>\n" +
                            "</ol>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <ol type="a">
                        <li>Lion</li>
                        <ol>
                            <li>Baby lion</li>
                            <li>Grown up lion</li>
                        </ol>
                        <li>Zebra</li>
                        <li>Giraffe</li>
                    </ol>
                    <hr/>
                    An other type of list is the description list, that have items with a description of each of them.
                    To do it, you use the tag <i>&lt;dl&gt;&lt;\dl&gt;</i> as the main,
                    the <i>&lt;dt&gt;&lt;/dt&gt;</i> to do the items, and the tag <i>&lt;dd&gt;&lt;/dd&gt;</i>
                    to do a description. Here is an exemple:
                    <CopyBlock
                        text={
                            "<dl>\n" +
                            "   <dt>Lion</dt>\n" +
                            "   <dd>- has a big mane</dd>\n" +
                            "   <dt>Zebra</dt>\n" +
                            "   <dd>- has black and white stripes</dd>\n" +
                            "   <dt>Giraffe</dt>\n" +
                            "   <dd>- has a thin and long neck</dd>\n" +
                            "</dl>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    And here is how the code is displayed:
                    <hr/>
                    <dl>
                        <dt>Lion</dt>
                        <dd>- has a big mane</dd>
                        <dt>Zebra</dt>
                        <dd>- has black and white stripes</dd>
                        <dt>Giraffe</dt>
                        <dd>- has a thin and long neck</dd>
                    </dl>
                    <hr/>
                    <h4>Exercise</h4>
                    Create an HTML page with a list of subjects your class is studying during this term and for each
                    subject (like maths, geography...) insert a sublist of topics you have been focusing on
                    (like equations, the lakes of Zambia...). Use an ordered list for the subjects and an unordered one
                    for the topics.
                    <h4>Solution</h4>
                    This solution is just an example and your content may vary.
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>The subjects of my term</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "           <h1>The subjects of my term</h1>\n" +
                            "           <ol>\n" +
                            "                <li>\n" +
                            "                    Maths:\n" +
                            "                    <ul>\n" +
                            "                        <li>Equations</li>\n" +
                            "                        <li>Trigonometry</li>\n" +
                            "                        <li>Algebra</li>\n" +
                            "                    </ul>\n" +
                            "                </li>\n" +
                            "                <li>\n" +
                            "                    Geography:\n" +
                            "                    <ul>\n" +
                            "                        <li>The lakes of Zambia</li>\n" +
                            "                        <li>Environmental sustainability</li>\n" +
                            "                        <li>Biodiversity</li>\n" +
                            "                    </ul>\n" +
                            "                </li>\n" +
                            "                <li>\n" +
                            "                    ...\n" +
                            "                    <ul>\n" +
                            "                        <li>...</li>\n" +
                            "                    </ul>\n" +
                            "                </li>\n" +
                            "            </ol>" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <h4>The subjects of my term</h4>
                    <ol>
                        <li>
                            Maths:
                            <ul>
                                <li>Equations</li>
                                <li>Trigonometry</li>
                                <li>Algebra</li>
                            </ul>
                        </li>
                        <li>
                            Geography:
                            <ul>
                                <li>The lakes of Zambia</li>
                                <li>Environmental sustainability</li>
                                <li>Biodiversity</li>
                            </ul>
                        </li>
                        <li>
                            ...
                            <ul>
                                <li>...</li>
                            </ul>
                        </li>
                    </ol>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Lists;
