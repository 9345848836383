import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class WritingHTML extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"External files and using HTML in Javascript"}
                       description={"How to create an external Javascript file and add HTML."}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"External files"} youtubeURL={"https://youtu.be/CRRcYpxb_bY"}
                              localVideoPath={"videos/Javascript/720p/04_UsingExternalFiles.mp4"}
                >
                    To create a Javascript file you need to use the extension .js at the end of the name of the document.
                    It is added in the HTML using the <i>script</i> tag with the <i>src</i> attribute. You can set the js file anywhere 
                    on the body and it will display at the same place on your page. <br/>
                    For example, if you have a file called <i>script.js</i> in the same folder as the HTML file, you can add it to the HTML
                    using the following code:
                    <CopyBlock
                        text={
                            "               <script src=\"script.js\"></script>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    This way of embedding Javascript code is called external file. It is a good practice to use external files
                    because it makes the code more organized and easier to read. It also makes the code reusable, so you can use it on
                    multiple pages.
                    <h4>Exercise</h4>
                    Create and link the Js file to the HTML. After that, recreate the code of the last class, using the <i>document.write</i> and
                    <i>alert</i> and comment.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Adding HTML code"} youtubeURL={"https://youtu.be/mbkKbhSbWKA"}
                              localVideoPath={"videos/Javascript/720p/05_WritingHTMLInJavascript.mp4"}
                >
                    In the <i>document.write</i> any HTML tag can be written inside the quotation marks as text,
                    just like the following example:
                    <CopyBlock
                        text={
                            "               document.write(\"<h1>Title</h1\");\n" +
                            "               document.write(\"<br/>\");\n" +
                            "               document.write(\"<p>paragraph</p\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    If you put the <i>script</i> tag in the <i>head</i> of the HTML, the code will be executed before
                    the HTML is loaded and the text will be displayed before the HTML. If you put the <i>script</i>
                    tag in the <i>body</i> of the HTML, the code will be executed at the moment when the tag is found
                    in the HTML code and the text will be displayed instead of the <i>script</i> tag.
                    <br/>
                    If you need to use quotes inside the <i>document.write</i> you can use the backslash (\) to escape
                    the quotes or use single quotes instead,
                    like in the following example:
                    <CopyBlock
                        text={
                            "               document.write(\"<p>\\\"This is a quote with escaped double quotes\\\"</p>\");\n" +
                            "               document.write('<p>\\\"This is a quote with single quotes\\\"</p>');\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    <h4>Exercise</h4>
                    Create an HTML page with some text encapsulated by two horizontal lines. Use a javascript file to
                    add the horizontal lines to the page.
                    <h4>Solution</h4>
                    The HTML code, inside the <i>index.html</i> file, should be:
                    <CopyBlock
                        text={
                            "               <html lang=\"en\">\n" +
                            "                   <head>\n" +
                            "                       <title>Javascript test</title>\n" +
                            "                   </head>\n" +
                            "                   <body>\n" +
                            "                       <script src=\"script.js\"></script>\n" +
                            "                       <p>This is a paragraph</p>\n" +
                            "                       <script src=\"script.js\"></script>\n" +
                            "                   </body>\n" +
                            "               </html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    The Javascript code, inside the <i>script.js</i> file, should be:
                    <CopyBlock
                        text={
                            "               document.write(\"<hr/>\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />

                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default WritingHTML;
