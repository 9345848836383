import React from "react";

// reactstrap components
import ReactPlayer from "react-player";

// core components

class SubLesson extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (<>
                <h3 className="title">Lesson {this.props.lessonIndex+1}.{this.props.subLessonIndex+1} -
                    {this.props.subLesson.title}</h3>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center',
                }}>
                    <ReactPlayer url={this.props.subLesson.video}/>
                </div>
                <h5 className="description mt-3">
                    {this.props.subLesson.description}
                </h5>
            </>
        );
    }
}

export default SubLesson;
