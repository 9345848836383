import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CopyBlock} from "react-code-blocks";


class StyleTagsAndExternalStyleSheets extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Style tags and external style sheets"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Style tags"}
                              youtubeURL={"https://youtu.be/q4Mxv3gr5mc"}
                              localVideoPath={"videos/CSS/720p/09_StyleTags.mp4"}
                >
                    Up until now we've seen how to add CSS to HTML by
                    using the style attribute. However, you can also add CSS to HTML by using style tags. Inside the
                    head tag, you can add a style tag, and inside the style tag you can add CSS code. We can use a
                    CSS selector to select the HTML element or elements that we want to style. More on CSS selectors
                    will be explained in a following lesson. For now, we can imagine that we can use as selector the
                    name of the HTML element, for example, the <i>p</i> element or the <i>body</i> element. This will
                    mean that all the style changes that we apply to that HTML element will be applied equally to all
                    the <i>p</i> elements or all the <i>body</i> elements. For example, if we want to change the
                    color of all the <i>h1</i> elements and of the <i>body</i>,
                    we can write the following CSS code inside the style tag:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "<head>\n" +
                            "    <title>Document</title>\n" +
                            "    <style>\n" +
                            "        body {\n" +
                            "            color: blue;\n" +
                            "        }\n" +
                            "        h1 {\n" +
                            "            color: green;\n" +
                            "        }\n" +
                            "    </style>\n" +
                            "</head>\n" +
                            "<body>\n" +
                            "    <h1>CSS tutorial</h1>\n" +
                            "    <p>This is a paragraph.</p>\n" +
                            "    <h1>A third header</h1>\n" +
                            "    <p>Style tags are a great way to make using CSS easier!</p>\n" +
                            "    <h1>Another header</h1>\n" +
                            "</body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <div style={{backgroundColor: "white"}}>
                        <h3 style={{color: "green"}}>CSS tutorial</h3>
                        <p style={{color: "blue"}}>This is a paragraph.</p>
                        <h3 style={{color: "green"}}>A third header</h3>
                        <p style={{color: "blue"}}>Style tags are a great way to make using CSS easier!</p>
                        <h3 style={{color: "green"}}>Another header</h3>
                    </div>
                    <hr/>
                    <h4>Exercise</h4>
                    Using the style tag, change the background color of the <i>p</i> elements to yellow,
                    the color of the <i>h1</i> elements to blue, and of the <i>h2</i> elements to green. The final result
                    should look like this:
                    <hr/>
                    <div style={{backgroundColor: "white"}}>
                        <h3 style={{color: "blue"}}>A header 1</h3>
                        <p style={{backgroundColor: "yellow"}}>This is a paragraph.</p>
                        <h4 style={{color: "green"}}>An header 2</h4>
                        <p style={{backgroundColor: "yellow"}}>Style tags are a great way to make using CSS easier!</p>
                        <h4 style={{color: "green"}}>Another header 2</h4>
                    </div>
                    <hr/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "<head>\n" +
                            "    <title>Document</title>\n" +
                            "    <style>\n" +
                            "        p {\n" +
                            "            background-color: yellow;\n" +
                            "        }\n" +
                            "        h1 {\n" +
                            "            color: blue;\n" +
                            "        }\n" +
                            "        h2 {\n" +
                            "            color: green;\n" +
                            "        }\n" +
                            "    </style>\n" +
                            "</head>\n" +
                            "<body>\n" +
                            "    <h1>A header 1</h1>\n" +
                            "    <p>This is a paragraph.</p>\n" +
                            "    <h2>An header 2</h2>\n" +
                            "    <p>Style tags are a great way to make using CSS easier!</p>\n" +
                            "    <h2>Another header 2</h2>\n" +
                            "</body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"External style sheets"}
                              youtubeURL={"https://youtu.be/2P_LUPc2HrM"}
                    localVideoPath={"videos/CSS/720p/10_ExternalStyleSheets.mp4"}
                >
                    An even more flexible way to add CSS to HTML is by using external style sheets. An external
                    style sheet is a CSS file that contains all the CSS code that we want to use. We can then link
                    this CSS file to our HTML file by using the link tag. The link tag has two attributes:
                    the rel attribute and the href attribute. The rel attribute specifies the relationship between
                    the HTML file and the file that is linked. In this case, the relationship is that the HTML file
                    is a <i>stylesheet</i>. The href attribute specifies the path to the CSS file that we want to link.
                    For example, if we have a CSS file called <i>style.css</i> in the same folder as our HTML file,
                    we can link it to our HTML file by writing the following code inside the head tag:
                    <CopyBlock
                        text={
                            "<head>\n" +
                            "    <title>Document</title>\n" +
                            "    <link rel=\"stylesheet\" href=\"style.css\">\n" +
                            "</head>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        highlight={"3"}
                        codeBlock
                    />
                    The good thing about using external style sheets is that we can use the same CSS file for
                    multiple HTML files. For example, if we have a website with multiple pages, we can use the same
                    CSS file for all of them. This way, we don't have to write the same CSS code for each HTML file.
                    For this reason, external style sheets are the most common way to add CSS to HTML.
                    <br/>
                    Inside the CSS file we can write code in the same way as we did in the style tag.
                    <h4>Exercise</h4>
                    Use the same code that we used in the last exercise about style tags, but this time
                    create a CSS file called <i>style.css</i> and link it to the HTML file. Observe that the result
                    is the same.
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default StyleTagsAndExternalStyleSheets;

