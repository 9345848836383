import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class IfAndSwitch extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"If and switch case"}
                       description={""}>
                        {/* TODO: description */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"If statements"} youtubeURL={"https://youtu.be/Pi7sqKg11O0"}
                              localVideoPath={""}
                >
                   You can use it in HTML to check a condition and execute some code if the state is true. The element is created
                following the example:
                <CopyBlock
                        text={
                            "               if(condition){\n"+
                            "                   code\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                />
                    Here are some boolean and relational operators that are used to do comparisons:
                    <ul>
                        <li>&& :and</li>
                        <li>|| :or</li>
                        <li>! :not</li>
                        <li>== :equal</li>
                        <li>!= :different</li>
                        <li>{'>'} :more than</li>
                        <li>{'>='} :greater than or equal to</li>
                        <li>{'<'} :less than</li>
                        <li>{'<='} :less than or equal to</li>
                        {/* check if this display works */}
                    </ul>
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Practicing if statements"} youtubeURL={"https://youtu.be/C3LkAdsQKz0"}
                              localVideoPath={""}
                >
                    To check multiple states and conditions you can link the ifs using else, like the example below: 
                <CopyBlock
                    text={
                        "               if(condition1) {\n"+
                        "                   code\n"+
                        "               }else if(condition2) {\n"+
                        "                   code\n"+
                        "               }else if(condition3) {\n"+
                        "                   code\n"+
                        "               }\n"
                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={2}
                              title={"Switch Statements"} youtubeURL={"https://youtu.be/in_At_MyLiA"}
                              localVideoPath={""}
                >
                    If you need to check multiple states of one variable, instead of doing a long sequence of ifs and else, you can
                use a switch to execute a piece of code. Here is his structure:  
                <CopyBlock
                        text={
                            "               switch(variable){\n"+
                            "                   case condition1:\n"+
                            "                       code;\n"+
                            "                       break;\n"+
                            "                   case condition2:\n"+
                            "                       code;\n"+
                            "                       break;\n"+
                            "                   case condition3:\n"+
                            "                       code;\n"+
                            "                       break;\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default IfAndSwitch;
