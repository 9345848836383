import {atomOneLight, CodeBlock, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import lion from "../../../assets/img/lion.jpg";

class Links extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={3} title={"Links"}
                       description={"How to allow the user to navigate to different websites and inside your " +
                       "own website"}>
                <SubLessonJSX lessonIndex={3} subLessonIndex={0}
                              title={"Links"} youtubeURL={"https://youtu.be/pTpkvZhoZM4"}
                              localVideoPath={"videos/HTML/720p/08_Links.mp4"}
                >
                    A link is a clickable element on a website, that, when clicked, redirects the user to another
                    page. It could be a page of an external website, a different page of the same website or even
                    a file.
                    <h4>Links to external websites (absolute links)</h4>
                    A link to an external website, for example to Wikipedia, can be written in this way:
                    <CopyBlock
                        text={
                            "<a href=\"https://www.wikipedia.org\">Wikipedia homepage</a>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Browsers will automatically change the color and feel of the text that represents the link
                    to reveal to the reader that is a link. For example the above link is displayed as: {}
                    <a href="https://www.wikipedia.org">Wikipedia homepage</a> <br/>
                    Every link element starts with a <i>&lt;a&gt;</i> tag followed by the <i>href</i> property
                    which contains the value of the link, in this case <i>https://www.wikipedia.org</i>. Here
                    it's important to specify the full value of the website link, do not forget the <i>
                    https://</i> part! <br/>
                    Note that if the computer that you're working on doesn't have an internet connection
                    you won't be able to access the link to the external website, as the browser will give
                    an error. <br/>
                    <h4>Links to a different page in the same website (relative links)</h4>
                    A relative link is a link to a webpage that is on the website you are currently using. This
                    allows you to easily navigate inside different pages of the same website. <br/>
                    For this reason we don't have to state the whole link with the <i>https://</i> part, but
                    we only need to specify the path of the other page relative to the current page. A path
                    indicates the position of a file by listing the folders that contain it, separated by a
                    forward slash. <br/>
                    To illustrate this let's imagine we have a folder called <i>website</i> that contains two
                    additional folders, one called <i>pages</i> that contains the files <i>index.html</i> and
                    <i>about.html</i> and one other folder called <i>blog</i> containing the file {}
                    <i>post.html</i>. The file structure looks like this:
                    <CodeBlock
                        text={
                            "- website/\n" +
                            "  - pages/\n" +
                            "    - index.html\n" +
                            "    - about.html\n" +
                            "  - blog/\n" +
                            "    - post1.html"
                        }
                        showLineNumbers={false}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    To include relative links from the <i>index.html</i> page to the {}
                    <i>about.html</i> page and the <i>post1.html</i> page we will write this:
                    <CopyBlock
                        text={
                            "<a href=\"./about.html\">About</a>\n" +
                            "<a href=\"../blog/post1.html\">Post 1</a>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Line one uses the token <i>./</i> which indicates the current folder. In fact, the {}
                    <i>about.html</i> file is in the same folder as the <i>index.html</i> from which it is linked.
                    Line two uses the token <i>../</i> (notice the two dots), which indicates one folder up in
                    the folder structure (in this case the <i>website</i> folder). This is necessary to be able
                    to reach the <i>blog</i> folder. <br/>
                    Using <i>./</i> and <i>../</i> you can navigate the folders in your websites and link to any
                    page.
                    <h4>Links to files</h4>
                    Not only is possible to link to pages, but it's also possible to link to files, like images
                    pdf files etc. You can link to non-html content that same way you link to html content,
                    just be sure to include the correct file extension. <br/>
                    For example if we want the user to open an image that is inside the <i>img</i> directory of
                    our website we would write:
                    <CopyBlock
                        text={
                            "<a href=\"../../../assets/img/lion.jpg\">Browse the image of a lion</a>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    We will play around with this in the exercise section.
                    <h4>Opening links in new tabs</h4>
                    In some cases, especially with links to external websites you don't want the other website
                    to open in the same window or tab of your own website, but in a new tab. In this case the user
                    can always come back to your website very easily. You can make a link open in a new tab
                    just by adding the following property to the <i>a</i> tag: <i>target="_blank"</i>.
                    For example to make the previous wikipedia link open in a new tab write:
                    <CopyBlock
                        text={
                            "<a href=\"https://www.wikipedia.org\" target=\"_blank\">Wikipedia homepage</a>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    You can check out the different behaviour of the browser: {}
                    <a href="https://www.wikipedia.org">Normal link</a> vs {}
                    <a href="https://www.wikipedia.org" target="_blank">Link that opens in new tab</a>. <br/>

                    <h4>Exercise</h4>
                    Create a page that, after a header, has a link to:
                    <ul>
                        <li>Wikipedia, opening in a new tab</li>
                        <li>A second page of your same website, opening in the same window</li>
                        <li>The image of a lion</li>
                    </ul>
                    You can create a second page of your website just by creating a second html file in the same
                    directory or folder of your first one, for example called <i>my_second_page.html</i>. <br/>
                    You can download a picture of a lion <a href={lion} download={"lion.jpg"}>here</a> and
                    place it in the same folder as the other files.
                    <h4>Solution</h4>
                    The main file, for example called <i>index.html</i>:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>Various links</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "            <a href=\"https://www.wikipedia.org\" target=\"_blank\">Wikipedia homepage</a><br\>\n" +
                            "            <a href=\"./my_second_page.html\">My second page</a><br\>\n" +
                            "            <a href=\"./lion.jpg\">The lion image</a><br\>\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    The second, linked, file, for example called <i>my_second_page.html</i>:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>The second page</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          This is a second page, that can be filled with anything, for example" +
                            " with a blog post.\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    In the end you should have one single folder with the following files inside:
                    <ul>
                        <li><i>index.html</i></li>
                        <li><i>my_second_file.html</i></li>
                        <li><i>lion.jpg</i></li>
                    </ul>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Links;
