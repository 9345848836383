import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class Inputs extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Towards JavaScript: Inputs"}
                       description={"In this lesson we look at inputs, a particular tag used to get input, like " +
                       "text and passwords, from the user. It's a good bridge towards two other important topics in " +
                       "web development: the JavaScript programming language and the CSS styling language. "}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"JavaScript and CSS"}>
                    Let's starting by having a quick look at what are JavaScript and CSS.
                    <h4>JavaScript</h4>
                    Javascript was built with the purpose of making websites more responsive and dynamic.
                    It makes websites able to respond to user inputs such as pressing a button, submitting a form field
                    and also able to change dynamically, for example by showing a image gallery where the next image
                    is automatically displayed after 10s or when the user presses the "next" button. <br/>
                    The JavaScript language is run directly in the browser, so on the side of the client or in other
                    words on the computer of the person viewing the website.
                    <h4>CSS</h4>
                    CSS stands for Cascading Style Sheet and is used to change the default appearance of HTML elements.
                    It allows web developers to define borders, colors, font styles, dimensions and much more regarding
                    an HTML element. Ultimately, it's what makes webpages look good and have their own unique look.
                    <br/>
                    <br/>
                    We will have a deeper look at JavaScript and CSS in a following course, but we wanted to give
                    an introduction to them, as they are also a cornerstone of web development, as much as HTML.
                    Moreover they help us to understand the following section.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Inputs"} youtubeURL={"https://youtu.be/vj78j_Sy3uM"}
                              localVideoPath={"videos/HTML/720p/14_InputAndForms.mp4"}
                >
                    The inputs are elements used to create forms and accept data from the user.
                    They are not functional just using HTML, needing JavaScript to do something with the data.
                    Nonetheless it's good to have an introduction to them, so that when you'll learn about JavaScript
                    you will understand the HTML part.
                    <br/>
                    The main tag is <i>&lt;input/&gt;</i>, that can be changed, according the type of
                    the data you want to collect, using the <i>type</i> attribute.
                    Here are some examples of input tags:<br/>
                    <CopyBlock
                        text={
                            '<input type="text"/>\n' +
                            '<input type="password"/>\n' +
                            '<input type="number"/>\n' +
                            '<input type="url"/>\n' +
                            '<input type="email"/>\n' +
                            '<input type="date"/>\n' +
                            '<input type="file"/>\n' +
                            '<input type="checkbox"/>\n' +
                            '<input type="radio" />\n' +
                            '<input type="color"/>\n' +
                            '<input type="time" />\n' +
                            '<input type="submit"/>\n'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Let's look at the most important ones one by one:
                    <CopyBlock
                        text={
                            '<input type="text"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Looks like this: <input type="text"/><br/>
                    and allows to input any kind of text.
                    <CopyBlock
                        text={
                            '<input type="password"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={2}
                    />
                    Looks like this: <input type="password"/><br/>
                    and allows to input a password; in fact if you try to type inside it you will see that the
                    characters you type will not be visible to you, to enforce privacy.
                    <CopyBlock
                        text={
                            '<input type="number"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={3}
                    />
                    Looks like this: <input type="number"/><br/>
                    and forces the user to enter only numerical values, no other inputs.
                    <CopyBlock
                        text={
                            '<input type="date"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={6}
                    />
                    Looks like this: <input type="date"/><br/>
                    and allows the user to select a specific date from the calendar.
                    It often goes together with the <i>"time"</i> input, to let the user select a time too.
                    <br/>
                    <CopyBlock
                        text={
                            '<input type="file"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={7}
                    />
                    Looks like this: <input type="file"/><br/>
                    and allows the user to select a file to be uploaded. Unfortunately, until we will learn about
                    JavaScript we will not be able to use this file in any way.
                    <CopyBlock
                        text={
                            '<input type="checkbox"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={8}
                    />
                    Looks like this: <input type="checkbox"/><br/>
                    and allows the user to do a toggle selection of a property, for example to answer a yes-no question.
                    <CopyBlock
                        text={
                            '<input type="radio"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={9}
                    />
                    Looks like this: <input type="radio"/><br/>
                    and, similarly to the checkbox, allows the uses to do a toggle selection of one or more properties.
                    The difference with the checkbox is that radiobuttons can be chained or grouped in order to allow
                    the user to only choose one option. In order to do this, group more than one radio button under
                    the same name, giving all of them the <i>name</i> property. For example:
                    <CopyBlock
                        text={
                            'Do you like more apples, bananas or mangos?<br/>\n' +
                            '<input name="fruits" type="radio"/> apples <br/>\n' +
                            '<input name="fruits" type="radio"/> bananas <br/>\n' +
                            '<input name="fruits" type="radio"/> mangos <br/>\n'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <hr/>
                    Do you like more apples, bananas or mangos?<br/>
                    <input name="fruits" type="radio"/> apples<br/>
                    <input name="fruits" type="radio"/> bananas<br/>
                    <input name="fruits" type="radio"/> mangos<br/>
                    <hr/>
                    As you can see, you can select only one option among the three. If we had used <i>checkbox</i>
                    instead of <i>radio</i>, you could have checked all three fruits, what a pity.
                    <CopyBlock
                        text={
                            '<input type="submit"/>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={10}
                    />
                    Looks like this: <input type="submit"/><br/>
                    And is used as a button that triggers the submission of the whole form. In other words it confirms
                    the information in the inputs and sends it to the server. The functionality of this button will
                    be enabled by JavaScript.
                    <br/>
                    <br/>
                    An additional HTML tag to keep in mind is the <i>form</i> tag. Usually when more than one input
                    is required from the user, all the input tags are enclosed in a form tag, which allows the browser
                    to know they are a group and that the submit button refers to all of them.
                    <br/><br/>
                    Another useful HTML tag in the case of user input is the <i>textarea</i> tag, which allows the user
                    to input a long string of text that doesn't fit in just one line. You can add default text that
                    will be displayed inside the text area before the use types and you can decide the size of the
                    text area using the attributes <i>rows</i> and <i>cols</i>. <br/>
                    For example this is an HTML text area:
                    <CopyBlock
                        text={
                            '<textarea rows={10} cols={100}>Default text</textarea>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    And this is how it gets displayed, feel free to play around with it:
                    <hr/>
                    <textarea rows={10} cols={100}>Default text</textarea>
                    <hr/>
                    <h4>Exercise</h4>
                    Let's tie different forms together with an exercise that designs a typical contact form.
                    Imagine you are giving the possibility to visitors of your website to get in contact with you.
                    You should ask them their email to get back to them, a title that summarizes what they want to
                    write you about, the full text of the enquiry and ask to select between three levels of urgency:
                    high, medium and low. Remember to surround all your HTML code regarding the input with the
                    <i>form</i> tag and to add the <i>submit</i> button.
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            '<form>\n' +
                            '    Your email: <input type="email" /><br/>\n' +
                            '    Subject: <input type="text" /><br/>\n' +
                            '    Enquiry:<br/>\n' +
                            '    <textarea rows={10} cols={100}>Your enquiry here...</textarea>\n' +
                            '    <br/>\n' +
                            '    Please select a level of urgency:<br/>\n' +
                            '    <input name="urgency" type="radio"/> low<br/>\n' +
                            '    <input name="urgency" type="radio"/> medium<br/>\n' +
                            '    <input name="urgency" type="radio"/> high<br/>\n' +
                            '    <input type="submit"/>\n' +
                            '</form>'
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <hr/>
                    Which will be displayed as:
                    <h5>Contact us</h5>
                    <form>
                        Your email: <input type="email" /><br/>
                        Subject: <input type="text" /><br/>
                        Enquiry:<br/>
                        <textarea rows={10} cols={100}>Your enquiry here...</textarea>
                        <br/>
                        Please select a level of urgency:<br/>
                        <input name="urgency" type="radio"/> low<br/>
                        <input name="urgency" type="radio"/> medium<br/>
                        <input name="urgency" type="radio"/> high<br/>
                        <input type="submit"/>
                    </form>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Inputs;
