import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CopyBlock} from "react-code-blocks";


class DisplayAndMargins extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Display and floats"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Display and floats"}
                              youtubeURL={"https://youtu.be/Bzd1Pnxqeic"}
                              localVideoPath={"videos/CSS/720p/05_DisplayAndFloats.mp4"}
                >
                    Floats and displays allow you to change the way the elements are displayed on the page and interact
                    with other elements. <br/>
                    The float property allows you to move an element to the left or right of the page. <br/>
                    It has three possible values:
                    <ul>
                        <li><i>left</i> which moves the element to the left of the page</li>
                        <li><i>right</i> which moves the element to the right of the page</li>
                        <li><i>none</i> which is the default value and does not move the element</li>
                    </ul>
                    The other elements of the page, for example the text, will wrap around the floated element. <br/>
                    The float property is often used to create a layout with a sidebar on the left or right of the page.
                    <br/>
                    For example the following HTML code:
                    <CopyBlock
                        text={
                            "<ul style=\"float:left; background-color:lightblue;\">\n" +
                            "     <li>Home</li>\n" +
                            "     <li>About</li>\n" +
                            "     <li>Contact Us</li>\n" +
                            "     <li>Blogs</li>\n" +
                            "</ul>\n" +
                            "\n" +
                            "<p>You can use the display and float CSS attributes to change the way that element on your " +
                            "website are displayed on the screen. You can notice that the text wraps around the element " +
                            "which is marked with the float property. Depending if you use the left or right value for " +
                            "the float property, the text will wrap around the element on the left or right side.</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={true}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <hr/>
                    <ul style={{float:"left", backgroundColor:"lightblue"}}>
                        <li>Home</li>
                        <li>About</li>
                        <li>Contact Us</li>
                        <li>Blogs</li>
                    </ul>
                    <p>You can use the display and float CSS attributes to change the way that element on your
                        website are displayed on the screen. You can notice that the text wraps around the element
                        which is marked with the float property. Depending if you use the left or right value for
                        the float property, the text will wrap around the element on the left or right side.
                    </p>
                    <hr/>
                    The display property allows you to change the way the element is displayed on the page.
                    <br/>
                    There are several possible values for the display property, but the most important ones are:
                    <ul>
                        <li><i>block</i> which is the default value and displays the element as a block</li>
                        <li><i>inline</i> which displays the element as an inline element</li>
                        <li><i>none</i> which hides the element</li>
                    </ul>
                    You can find a full list of CSS display values at {" "}
                    <a href="https://www.w3schools.com/cssref/pr_class_display.asp" target="_blank" rel="noopener noreferrer">
                        w3schools
                    </a>
                    For example, if we change the display property of the list items to inline, the list will be
                    displayed as a single line:
                    <CopyBlock
                        text={
                            "<ul style=\"float:left; background-color:lightblue;\">\n" +
                            "     <li style=\"display:inline;\">Home</li>\n" +
                            "     <li style=\"display:inline;\">About</li>\n" +
                            "     <li style=\"display:inline;\">Contact Us</li>\n" +
                            "     <li style=\"display:inline;\">Blogs</li>\n" +
                            "</ul>\n"
                        }
                        language={"html"}
                        showLineNumbers={true}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <hr/>
                    <ul style={{float:"left", backgroundColor:"lightblue"}}>
                        <li style={{display:"inline"}}>Home</li>
                        <li style={{display:"inline"}}>About</li>
                        <li style={{display:"inline"}}>Contact Us</li>
                        <li style={{display:"inline"}}>Blogs</li>
                    </ul>
                    <br/>
                    <hr/>
                    <h4>Exercise</h4>
                    Create a website where you have two floating lists and a paragraph of text.
                    The list on the left is ordered and the list on the right is unordered and displayed inline.
                    <br/>
                    The website should look something like this:
                    <hr/>
                    <ul style={{float:"left", backgroundColor:"lightblue"}}>
                        <li>Apples</li>
                        <li>Bananas</li>
                        <li>Oranges</li>
                        <li>Peaches</li>
                    </ul>
                    <ul style={{float:"right", backgroundColor:"lightblue"}}>
                        <li style={{display:"inline"}}>Cars</li>
                        <li style={{display:"inline"}}>Trucks</li>
                        <li style={{display:"inline"}}>Motorcycles</li>
                        <li style={{display:"inline"}}>Boats</li>
                    </ul>
                    <p>The content of the page can go here</p>
                    <br/>
                    <br/>
                    <hr/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "<head>\n" +
                            "    <title>Display and Floats</title>\n" +
                            "</head>\n" +
                            "<body>\n" +
                            "    <ul style=\"float:left; background-color:lightblue;\">\n" +
                            "        <li>Apples</li>\n" +
                            "        <li>Bananas</li>\n" +
                            "        <li>Oranges</li>\n" +
                            "        <li>Peaches</li>\n" +
                            "    </ul>\n" +
                            "    <ul style=\"float:right; background-color:lightblue;\">\n" +
                            "        <li style=\"display:inline;\">Cars</li>\n" +
                            "        <li style=\"display:inline;\">Trucks</li>\n" +
                            "        <li style=\"display:inline;\">Motorcycles</li>\n" +
                            "        <li style=\"display:inline;\">Boats</li>\n" +
                            "    </ul>\n" +
                            "    <p>The content of the page can go here</p>\n" +
                            "</body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        showLineNumbers={true}
                        theme={atomOneLight}
                        codeBlock
                    />


                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default DisplayAndMargins;

