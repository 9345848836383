import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class WhatAndWhyHtml extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"What is and why html"}
                       description={"An introduction to HTML, the language of the internet."}>
                <SubLessonJSX lessonIndex={0} subLessonIndex={0}
                              title={"What and why html"}
                              youtubeURL={"https://youtu.be/Ny1g1eQHnCI"}
                              localVideoPath={"videos/HTML/720p/01_IntroductionHTML.mp4"}
                >
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={0} subLessonIndex={1}
                              title={"Choosing a text editor"} youtubeURL={"https://youtu.be/uGGTXUsk7EM"}
                              localVideoPath={"videos/HTML/720p/02_ChoosingATextEditorHTML.mp4"}
                >
                    We recommend to use Notepad++ as text editor for this course, as it's very simple. Please check now
                    if it's installed on your machine, if not download and install it from
                    <a href="https://notepad-plus-plus.org/downloads/"> here</a>.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={0} subLessonIndex={2}
                              title={"Creating your first HTML file"} youtubeURL={"https://youtu.be/zKRHZ092ICM"}
                              localVideoPath={"videos/HTML/720p/03_CreatingAnHTMLFile.mp4"}
                >
                    Follow these steps:
                    <ol>
                        <li>Create a folder (directory) for your project, choose the name depending on your
                            creativity.
                        </li>
                        <li>Create a new file called <i>index.html</i> and open it in your text editor (e.g. Notepad++)
                        </li>
                        <li>Insert the following text into the file:
                            <CopyBlock
                                text={"Hello world"}
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                        </li>
                        <li>Go back to the files app and double click on the <i>index.html</i> file
                            to open it in your browser. You'll see <i>Hello world</i> displayed. Congratulations,
                            you just created your first HTML file. :)
                        </li>
                        <li>Delete <i>Hello world</i> from the file and copy and paste the following code inside.
                            You can use the little copy icon on the top right of the code box.
                            Save the file in your text editor (e.g. Notepad++)
                            and reload the file from the browser to see the changes.
                            <CopyBlock
                                text={
                                    "<!DOCTYPE html>\n" +
                                    "<html>\n" +
                                    "     <head>\n" +
                                    "          <title>My Website Title</title>\n" +
                                    "     </head>\n" +
                                    "     <body>\n" +
                                    "          Hello World\n" +
                                    "     </body>\n" +
                                    "</html>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            <br/>
                            Let's have a look at it line by line:
                            <ul>
                                <li>
                                    <CopyBlock
                                        text={"<!DOCTYPE html>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        codeBlock
                                    />
                                    This line declares that the document is written in the HTML language. It's useful
                                    for the web browser to interpret what follows.
                                </li>
                                <li>
                                    <CopyBlock
                                        text={"<html>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        startingLineNumber={2}
                                        codeBlock
                                    />
                                    This is your first HTML tag. Those symbols with the <b>&lt;</b> and
                                    <b>&gt;</b> signs are called
                                    tags. HTML tags are used to structure and format the HTML document, and can
                                    be used to describe the layout of a website. <br/>

                                    Tags are usually composed of two elements: the opening tag and the closing tag.
                                    The opening tag doesn't contain a slash symbol <b>/</b>, while the closing one
                                    does. For example, this line is an opening tag and its corresponding closing tag
                                    can be found at line 9 (<b>&lt;/html&gt;</b>). Tags can contain both text or other
                                    tags inside. It's the same to say that tags can be nested.
                                </li>
                                <li>
                                    <CopyBlock
                                        text={"<head>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        startingLineNumber={3}
                                        codeBlock
                                    />
                                    This is what happens with the <b>&lt;head&gt;</b> tag, that is nested inside
                                    the <b>&lt;html&gt;</b> tag. Every HTML document has a <b>&lt;head&gt;</b>
                                    and a <b>&lt;body&gt;</b> tag. The head tag is useful to declare information
                                    about the page.
                                </li>
                                <li>
                                    <CopyBlock
                                        text={"<title>My Website Title</title>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        startingLineNumber={4}
                                        codeBlock
                                    />
                                    One possible of such information is the title of the webpage, which will be
                                    displayed by the browser on top of the tab.
                                </li>
                                <li>
                                    <CopyBlock
                                        text={"<body>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        startingLineNumber={5}
                                        codeBlock
                                    />
                                    The body tag contains all the real content of the page, for example our
                                    text <i>Hello world</i>.
                                </li>
                                <li>
                                    <CopyBlock
                                        text={"    </body>\n" +
                                        "</html>"}
                                        language={"html"}
                                        theme={atomOneLight}
                                        startingLineNumber={5}
                                        codeBlock
                                    />
                                    The remaining lines, close all the tags that were previously openeded. Every
                                    open tag must be closed. If there are nested tags they should be closed following
                                    the order in which they were opened (i.e. the last opened tag is the first to be
                                    closed)
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <h4>Exercise</h4>
                    Play around with it! Try modifying the text inside the body tag or inside the title
                    tag. Save the file in your text editor (e.g. Notepad++) and reload the file from the browser
                    to see the changes.
                    In particular make the title say <i>Great title</i> and the text say <i>Great text</i>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>Great title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          Great text\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default WhatAndWhyHtml;
