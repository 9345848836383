import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

import { ChromePicker } from 'react-color'


class ColorsMarginsAndPaddings extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Colors and margins & paddings"}
                       description={"Handle colors and how elements should position themselves with margins and paddings"}>
                <SubLessonJSX lessonIndex={1} subLessonIndex={0}
                              title={"Colors"}
                              youtubeURL={"https://youtu.be/jUFjErKxIPo"}
                              localVideoPath={"videos/CSS/720p/03_Colors.mp4"}
                >
                    Colors in CSS can be expressed in many ways, but the most common are:
                    <ul>
                        <li>
                            By their name, for example <i>red</i>, <i>blue</i>, <i>green</i>, <i>yellow</i> etc.<br/>
                             You can find a list of all the possible colors at {" "}
                            <a href={"https://www.w3schools.com/colors/colors_names.asp"}>W3schools color names</a>.
                        </li>
                        <li>By their RGB value, for example <i>rgb(255, 0, 0)</i> for red, <i>rgb(0, 0, 255)</i> for
                            blue, <i>rgb(0, 255, 0)</i> for green, <i>rgb(255, 255, 0)</i> for yellow etc. The RGB
                            value is composed of 3 components, the first one is for the red component, the second one
                            for the green component and the last one for the blue component. Each component can be
                            expressed in values from 0 to 255, where 0 is the minimum value and 255 is the maximum value.
                            For example, <i>rgb(255, 0, 0)</i> is the maximum red value, <i>rgb(0, 255, 0)</i> is the
                            maximum green value and <i>rgb(0, 0, 255)</i> is the maximum blue value. <i>rgb(255, 255,
                                255)</i> is the maximum value for all components, and <i>rgb(0, 0, 0)</i> is the minimum value
                            for all components. <br/>
                            You can play around with the RGB values and see the result at {" "}
                            <a href={"https://www.w3schools.com/colors/colors_rgb.asp"}>W3schools RGB values</a>.
                        </li>
                        <li>By their hexadecimal value, similarly to the RGB values,
                            for example <i>#ff0000</i> for red, <i>#0000ff</i> for blue,
                            <i>#00ff00</i> for green, <i>#ffff00</i> for yellow etc. The hexadecimal value is composed
                            of 6 characters, the first two are for the red component, the second two for the green
                            component and the last two for the blue component. Each component can be expressed in
                            hexadecimal values from 00 to ff, where 00 is the minimum value and ff is the maximum value.
                            For example, <i>#ff0000</i> is the maximum red value, <i>#00ff00</i> is the maximum green
                            value and <i>#0000ff</i> is the maximum blue value. <i>#ffffff</i> is the maximum value for
                            all components, and <i>#000000</i> is the minimum value for all components. <br/>
                            Hexadecimal values can be confusing. If you find them confusing too, you can use the
                            RGB values instead.
                        </li>
                    </ul>
                    Another good way of choosing a color is by using a color wheel. You can find a color wheel at {" "}
                    <a href={"https://www.w3schools.com/colors/colors_picker.asp"}>W3schools color wheel</a>.
                    <h4>Exercise</h4>
                    Write two different HTML paragraphs, they can have any text you want, but they should both have
                    the grey color, but one should be specified using the color name, and the other should be specified
                    using the RBG or hexadecimal value. <br/>
                    If you don't have internet to browse the W3schools website, you can use the following color
                    picker to choose grey. You can switch between hex and RBG using the right arrows:
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <ChromePicker/>
                    </div>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<p style=\"color: grey\">This is a grey paragraph</p>\n" +
                            "<p style=\"color: rgb(128, 128, 128)\">This is a grey paragraph</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />

                </SubLessonJSX>
                <SubLessonJSX lessonIndex={1} subLessonIndex={1}
                              title={"Margins and paddings"}
                              youtubeURL={"https://youtu.be/k4xA_Olo_Uo"}
                    localVideoPath={"videos/CSS/720p/04_MarginsAndPadding.mp4"}
                >
                    We can assign margins and paddings to elements. Margins are the space between the element and the
                    elements around it, and paddings are the space between the element and its content. <br/>
                    We can assign margins and paddings to elements in 3 different ways:
                    <ul>
                        <li>
                            We can assign a margin or padding to all sides of the element using the <i>margin</i> or
                            <i>padding</i> property. For example, if we want to assign a margin of 10 pixels to all
                            sides of an element, we can write <i>margin: 10px</i>. If we want to assign a padding of
                            10 pixels to all sides of an element, we can write <i>padding: 10px</i>.
                        </li>
                        <li>
                            We can assign a margin or padding to the top, right, bottom and left sides of the element
                            using the <i>margin-top</i>, <i>margin-right</i>, <i>margin-bottom</i> and
                            <i>margin-left</i> properties. For example, if we want to assign a margin of 10 pixels to
                            the top, 20 pixels to the right, 30 pixels to the bottom and 40 pixels to the left of an
                            element, we can write <i>margin-top: 10px; margin-right: 20px; margin-bottom: 30px;
                                margin-left: 40px;</i>.
                        </li>
                        <li>
                            We can also assign a margin or padding to all sides of the element using just by
                            specifying <i>margin</i> or <i>padding</i> and then the top, right, bottom and left values
                            separated by spaces. For example, if we want to assign a margin of 10 pixels to the top,
                            20 pixels to the right, 30 pixels to the bottom and 40 pixels to the left of an element, we
                            can write <i>margin: 10px 20px 30px 40px;</i>.
                        </li>
                    </ul>
                    <br/>
                    Here are a few examples of margins and paddings and how they are displayed. Notice the difference
                    between the margins and the paddings and how they are displayed in relation to other elements.
                    To better display the difference, the div (container element) has a background color.
                    <br/>
                    <br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"padding: 50px; background-color: lightblue;\">\n" +
                            "     Overall padding\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{padding: 50, backgroundColor: "lightblue"}}>
                            Overall padding
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"padding-left: 50px; padding-right: 50px; background-color: lightblue;\">\n" +
                            "     Individual side padding\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{paddingLeft: 50, paddingRight: 50, backgroundColor: "lightblue"}}>
                            Individual side padding
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"padding: 50px 25px 50px 25px;  background-color: lightblue;\">\n" +
                            "     Four side padding in same line\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{padding: "50px 25px 50px 25px", backgroundColor: "lightblue"}}>
                            Four side padding in same line
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"margin: 50px; background-color: lightblue\">\n" +
                            "     Overall margin\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{margin: 50, backgroundColor: "lightblue"}}>
                            Overall margin
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"margin-left: 50px; margin-right: 50px; background-color: lightblue\">\n" +
                            "     Individual side margin\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{marginLeft: 50, marginRight: 50, backgroundColor: "lightblue"}}>
                            Individual side margin
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"margin: 50px 25px 50px 25px; background-color: lightblue\">\n" +
                            "     Four side margin in same line\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{margin: "50px 25px 50px 25px", backgroundColor: "lightblue"}}>
                            Four side margin in same line
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    Of course you can also combine the padding and margin properties in the same line.
                    For example:
                    <br/><br/>
                    <CopyBlock
                        text={
                            "<p>Paragraph 1</p>\n" +
                            "<div style=\"padding: 50px; margin: 50px; background-color: lightblue\">\n" +
                            "     Overall padding and margin\n" +
                            "</div>\n" +
                            "<p>Paragraph 2</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which is displayed as:
                    <hr/>
                    <div>
                        Paragraph 1
                        <div style={{padding: 50, margin: 50, backgroundColor: "lightblue"}}>
                            Overall padding and margin
                        </div>
                        Paragraph 2
                    </div>
                    <hr/>
                    <br/><br/>
                    <h4>Exercise</h4>
                    Write an HTML file with a first part with a big margin, a second part with a big padding and
                    a third part with a big padding and a big margin.
                    All parts should have a background color.<br/>
                    When displayed it should look roughly like this:
                    <br/><br/>
                    <div style={{margin: 50, backgroundColor: "lightgreen"}}>
                        Part 1
                    </div>
                    <div style={{padding: 50, backgroundColor: "lightyellow"}}>
                        Part 2
                    </div>
                    <div style={{padding: 50, margin: 50, backgroundColor: "lightblue"}}>
                        Part 3
                    </div>
                    Play around with the values to understand better how margins and paddings work!
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "<head>\n" +
                            "    <title>Exercise</title>\n" +
                            "</head>\n" +
                            "<body>\n" +
                            "    <div style=\"margin: 50px; background-color: lightgreen\">\n" +
                            "        Part 1\n" +
                            "    </div>\n" +
                            "    <div style=\"padding: 50px; background-color: lightyellow\">\n" +
                            "        Part 2\n" +
                            "    </div>\n" +
                            "    <div style=\"padding: 50px; margin: 50px; background-color: lightblue\">\n" +
                            "        Part 3\n" +
                            "    </div>\n" +
                            "</body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        showLineNumbers={true}
                        theme={atomOneLight}
                        codeBlock
                    />


                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default ColorsMarginsAndPaddings;

