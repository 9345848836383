import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class AccessingHTML extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Accessing HTML, trigger events and timing"}
                       description={""}>
                        {/* TODO: description */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Accessing HTML elements"} youtubeURL={"https://youtu.be/pxBT4QgNhh4"}
                              localVideoPath={""}
                >
                    After importing the js file to the HTML one, you can access each tag and change their proprieties 
                    using Javascript. To create a variable that stores your component by its id, you can use the following code:
                    <CopyBlock
                            text={
                                "           var variable = document.getElementById(\"idTag\");"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                        />
                    As you know, the variable can be named following the idea of which tag it represents.
                    Once you get that, one way to change the attributes is by calling the variable name, dot, and what you want to change.
                    For example, the background color of a div that has "displayImages" as id:
                    <CopyBlock
                            text={
                                "           var divImages = document.getElementById(\"displayIOmages\");\n" +
                                "           divImages.style=\"background-color:grey;\""
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                            />
                    Another way is getting the exact propriety of the element:
                    <CopyBlock
                            text={
                                "           var divImages = document.getElementById(\"displayIOmages\");\n" +
                                "           divImages.style.backgroudColor=\"grey\";"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                            />
                    Is important to pay attention to where the script tag that sets the js is in your HTML document.
                    Remember that your code is executed line by line, so you must call all the alterations made by the Javascript after you
                    create the element.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Trigger Events"} youtubeURL={"https://youtu.be/jrI0WFCCLWY"}
                              localVideoPath={""}
                >
                    An event listener is a way to execute a function in Javascript when the user does something specific.
                    One example is when the button is clicked, but you can use this trigger event in almost all HTML tags. Once you had the 
                    function created, you can execute a javascript code simply by adding the onClick event in the HTML:
                    <CopyBlock
                            text={
                                "           <button onclick=\"yourFunction()\">click me\</button>"
                            }
                            language={"html"}
                            theme={atomOneLight}
                            codeBlock
                        />
                    Here are some other common events:
                    <ul>
                        <li>onload: the browser finish to load the page;</li>
                        <li>onchange: when a change happens to the element;</li>
                        <li>onmouseover: the mouse is over the component;</li>
                        <li>onmouseout: the mouse leave the segment;</li>
                        <li>onkeydown: when the user is pressing a key;</li>
                        <li>onkeyup: when he releases the key;</li>
                        <li>onscroll: when the element scrollbar is being scrolled;</li>
                        <li>oncopy: when the content of an element is copied.</li>
                    </ul>
                    There are still many others that can be used.
                    <br/>
                    Another way to do the same action is using event listeners. They are implemented in the Javascript code as an alteration 
                    in the attributes of a variable that contains your tag object.
                    <CopyBlock
                            text={
                                "           var startButton = document.getElementById(\"mainButton\");\n" +
                                "           startButton.addEventListener(\"click\", function());"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    Notice that the only difference, besides the language, is that using the event listeners you don't have to add "on" before
                    the type of event.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={2}
                              title={"Timing"} youtubeURL={"https://youtu.be/TxR0I9HHwag"}
                              localVideoPath={""}
                >
                    A useful tool to add time waiting before the execution of one function is the "setTimeOut". You can add it in the HTML
                    inside of the trigger event, in milliseconds, as in the following example:
                    <CopyBlock
                            text={
                                "           <button onClick=\"setTimeout(funcName, 3000)\">Click Me</button>"
                            }
                            language={"html"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    It is also possible to execute a function every determinate interval of time:
                    <CopyBlock
                            text={
                                "           <button onClick=\"setInterval(funcName, 3000)\">Click Me</button>"
                            }
                            language={"html"}
                            theme={atomOneLight}
                            codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default AccessingHTML;
