import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CourseHeader from "../../components/Courses/CourseHeader";
import pythonDef from '../../assets/courses/html.json';
import CourseIntro from "../../components/Courses/CourseIntro";
import { HashLink } from 'react-router-hash-link';

import JavascriptIntro from "./JavascriptLessons/JavascriptIntro";
import WritingHTML from "./JavascriptLessons/WritingHtml";
import Variables from "./JavascriptLessons/Variables";
import InputAndCalculator from "./JavascriptLessons/InputAndCalculator";
import ArraysAndFunctions from "./JavascriptLessons/ArraysAndFunctions";
import AccessingHTML from "./JavascriptLessons/AccessingHTML";
import MessagingApp from "./JavascriptLessons/MessagingApp";
import IfAndSwitch from "./JavascriptLessons/IfAndSwitch";
import JSObjects from "./JavascriptLessons/JSObjects";
import WhileAndFor from "./JavascriptLessons/WhileAndFor";
import PasswordChecker from "./JavascriptLessons/PasswordChecker";
import Quiz from "./JavascriptLessons/Quiz";
import TwoDArrays from "./JavascriptLessons/TwoDArrays";


const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

class Javascript extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseDef: pythonDef
    };
  }

  componentDidMount() {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("sidebar-collapse");
  }

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
            <CourseHeader>Javascript</CourseHeader>
          <div className="section section-about-us">
            <Container>
                <CourseIntro>
                    Javascript is a programming language that is used to add interactivity to web pages. <br/>
                    It is used to create dynamic web pages that can change their content based on user input. <br/>
                    In 2022, Javascript is the most popular programming language in the world and the most used on
                    <a href={"https://octoverse.github.com/2022/top-programming-languages"} target={"_blank"}> Github
                    </a>, a popular code repository.
                    <br/>
                    <br/><br/>
                    The videos of the course are taken, with consent, from <a
                    href={"https://www.mikedane.com/web-development/javascript/"} target="_blank">Mike Dane</a>.
                     We thank him for his contribution.<br/><br/>
                    <h5>Lessons list:</h5>
                    <HashLink smooth to={"#lesson1"} scroll={scrollWithOffset}>
                        Lesson 1 - Introduction and hello world
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson2"} scroll={scrollWithOffset}>
                        Lesson 2 - External files and using HTML in Javascript
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson3"} scroll={scrollWithOffset}>
                        Lesson 3 - Variables, strings and math
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson4"} scroll={scrollWithOffset}>
                        Lesson 4 - Getting input and building a calculator
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson5"} scroll={scrollWithOffset}>
                        Lesson 5 - Arrays and functions
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson6"} scroll={scrollWithOffset}>
                        Lesson 6 - Accessing HTML, trigger events and timing
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson7"} scroll={scrollWithOffset}>
                        Lesson 7 - Building a messaging app
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson8"} scroll={scrollWithOffset}>
                        Lesson 8 - If statements and switches
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson9"} scroll={scrollWithOffset}>
                        Lesson 9 - Objects
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson10"} scroll={scrollWithOffset}>
                        Lesson 10 - While and for loops
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson11"} scroll={scrollWithOffset}>
                        Lesson 11 - Building a password checker
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson12"} scroll={scrollWithOffset}>
                        Lesson 12 - Quiz
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson13"} scroll={scrollWithOffset}>
                        Lesson 13 - 2D arrays, nested loops
                    </HashLink><br/>
                    <br/>
                </CourseIntro>
                <JavascriptIntro lessonIndex={0}/>
                <WritingHTML lessonIndex={1}/>
                <Variables lessonIndex={2}/>
                <InputAndCalculator lessonIndex={3}/>
                <ArraysAndFunctions lessonIndex={4}/>
                <AccessingHTML lessonIndex={5}/>
                <MessagingApp lessonIndex={6}/>
                <IfAndSwitch lessonIndex={7}/>
                <JSObjects lessonIndex={8}/>
                <WhileAndFor lessonIndex={9}/>
                <PasswordChecker lessonIndex={10}/>
                <Quiz lessonIndex={11}/>
                <TwoDArrays lessonIndex={12}/>

            </Container>
          <DefaultFooter />
          </div>
        </div>
      </>
    );
  }
}

export default Javascript;
