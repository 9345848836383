import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class Tables extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Tables"}
                       description={"Create tables inside the html page in order to organize content"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Tables"} youtubeURL={"https://youtu.be/6RE5vCsPOS4"}
                              localVideoPath={"videos/HTML/720p/12_Tables.mp4"}
                >
                    A table is a way to array the data into rows and columns.
                    Let's start by looking at the most simple table we can create in HTML:
                    <CopyBlock
                        text={
                            "<table>\n" +
                            "   <tr>\n" +
                            "       <th>One</th>\n" +
                            "       <th>Two</th>\n" +
                            "   </tr>\n" +
                            "   <tr>\n" +
                            "       <td>Three</td>\n" +
                            "       <td>Four</td>\n" +
                            "   </tr>\n" +
                            "</table>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will be displayed as:
                    <hr/>
                    <table>
                       <tr>
                           <th>One</th>
                           <th>Two</th>
                       </tr>
                       <tr>
                           <td>Three</td>
                           <td>Four</td>
                       </tr>
                    </table>
                    <hr/>
                    In the example we can see the tags:
                    <ul>
                        <li><i>&lt;table&gt;</i> that defines the table and encapsulates all the content.</li>
                        <li><i>&lt;tr&gt;</i> that defines a table row. You should have as many of these tags as
                        many rows you want in your table. In this case we have two rows. Notice that HTML automatically
                        puts the first row in bold, to signal it represents the header of the table. More on this in
                        the next example. </li>
                        <li><i>&lt;td&gt;</i> that defines an element inside the row and it's short for table data. You
                        can also think of this element as a column inside the row.</li>
                    </ul>
                    <br/>
                    Let's now look at a more advanced example of a table, used to organize the anniversary of
                    3 brothers and how is displayed:
                    <CopyBlock
                        text={
                            "<table>\n" +
                            "   <caption>Wilson brothers</caption>\n" +
                            "   <thead>\n" +
                            "       <tr>\n" +
                            "           <th>Name</th>\n" +
                            "           <th>Anniversary</th>\n" +
                            "       </tr>\n" +
                            "   </thead>\n" +
                            "   <tbody>\n" +
                            "       <tr>\n" +
                            "           <td>Mark</td>\n" +
                            "           <td>15/03</td>\n" +
                            "       </tr>\n" +
                            "       <tr>\n" +
                            "           <td>Scoot</td>\n" +
                            "           <td>01/02</td>\n" +
                            "       </tr>\n" +
                            "       <tr>\n" +
                            "           <td>Harry</td>\n" +
                            "           <td>06/10</td>\n" +
                            "       </tr>\n" +
                            "   </tbody>\n" +
                            "</table>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <table>
                        <caption style={{textAlign: "center", captionSide: "top"}}>Wilson brothers</caption>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Anniversary</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Mark</td>
                            <td>15/03</td>
                        </tr>
                        <tr>
                            <td>Scoot</td>
                            <td>01/02</td>
                        </tr>
                        <tr>
                            <td>Harry</td>
                            <td>06/10</td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>Here is an explanation of the tags:</h4>
                    <ul>
                        <li>
                            <CopyBlock
                                text={
                                    "<table>\n" +
                                    "</table>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            As in the previous example, the <i>&lt;table&gt;</i> tag define a table and includes
                            all the table components
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<caption>" +
                                    "</caption>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            The <i>&lt;caption&gt;</i> tag is used in the header and defines the title of the table.
                            The title of the table will be displayed on top of the table, as you can see from the
                            example.
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<thead>\n" +
                                    "</thead>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            The <i>&lt;thead&gt;</i> (table head) tag, which we added compared to the previous example,
                            defines the header of the table. This is the part of the table that HTML will put in bold
                            and that doesn't contain real data, but explanation of the data.
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<tr>\n" +
                                    "</tr>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            As in the previous example, the <i>&lt;tr&gt;</i> tag (table row) creates a row in the table
                            and can be used both in the header and body.
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<th>" +
                                    "</th>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            <i>&lt;th&gt;</i> Is used in the header and creates a bold element in the row.
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<tbody>\n" +
                                    "</tbody>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            The <i>&lt;tbody&gt;</i> (table body) tag defines the body of the table. This tag should
                            be at the same indent leve as the <i>&lt;thead&gt;</i> tag, as they divide the table in
                            two parts, the header and the body.
                        </li>
                        <li>
                            <CopyBlock
                                text={
                                    "<td>" +
                                    "</td>"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                codeBlock
                            />
                            The <i>&lt;tf&gt;</i> (table data) ss used in the body and creates an element in the row.
                        </li>
                    </ul>
                    <h4>Exercise</h4>
                    Create a table that represents the number of classes and students per each of the last three
                    grades of your school. The table header will contain three columns, <i>grade</i>, for example 11,
                    <i>number of classes</i>, for example 2, <i>total number of students</i>, for example 80.
                    Give the table a title, for example <i>Number of students in my school</i>.
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<table>\n" +
                            "   <caption>Number of students in my school</caption>\n" +
                            "   <thead>\n" +
                            "       <tr>\n" +
                            "           <th>Grade</th>\n" +
                            "           <th>Number of classes</th>\n" +
                            "           <th>Total number of students</th>\n" +
                            "       </tr>\n" +
                            "   </thead>\n" +
                            "   <tbody>\n" +
                            "       <tr>\n" +
                            "           <td>10</td>\n" +
                            "           <td>1</td>\n" +
                            "           <td>40</td>\n" +
                            "       </tr>\n" +
                            "       <tr>\n" +
                            "           <td>11</td>\n" +
                            "           <td>2</td>\n" +
                            "           <td>80</td>\n" +
                            "       </tr>\n" +
                            "       <tr>\n" +
                            "           <td>12</td>\n" +
                            "           <td>2</td>\n" +
                            "           <td>65</td>\n" +
                            "       </tr>\n" +
                            "   </tbody>\n" +
                            "</table>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will look like:
                    <table>
                        <caption style={{textAlign: "center", captionSide: "top"}}>
                            Number of students in my school</caption>
                       <thead>
                           <tr>
                                   <th>Grade</th>
                                   <th>Number of classes</th>
                                   <th>Total number of students</th>
                               </tr>
                       </thead>
                       <tbody>
                           <tr>
                                   <td>10</td>
                                   <td>1</td>
                                   <td>40</td>
                               </tr>
                           <tr>
                                   <td>11</td>
                                   <td>2</td>
                                   <td>80</td>
                               </tr>
                           <tr>
                                   <td>12</td>
                                   <td>2</td>
                                   <td>65</td>
                               </tr>
                       </tbody>
                    </table>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Tables;
