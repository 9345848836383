import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import SubLesson from "./SubLesson";

// core components

class Lesson extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (<Row id={"lesson" + (this.props.lessonIndex+1).toString()}>
                <Col className="ml-auto mr-auto mb-4">
                    <Card style={{ borderRadius: 15 }}>
                        <CardBody>

                        <CardTitle>
                    <h2 className="title text-center">Lesson {this.props.lessonIndex+1} - {this.props.title}</h2>
                            </CardTitle>
                    <h5 className="description">
                        {this.props.description}
                    </h5>
                    {this.props.children}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        );
    }
}

export default Lesson;
