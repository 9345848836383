import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CopyBlock} from "react-code-blocks";
import positionImage from "../../../assets/img/positon.png";

class Position extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Position - static, relative, absolute, fixed"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Position - static, relative, absolute, fixed"}
                              youtubeURL={"https://youtu.be/_0Z1oNQ93Wo"}
                              localVideoPath={"videos/CSS/720p/06_Position.mp4"}
                >
                    There are four different position values in CSS: static, relative, absolute, and fixed. The default
                    position value is static. These are their meanings and examples:
                    <ul>
                        <li><i>static</i>: The element is positioned according to the normal flow of the document.
                        For example, the following code will be displayed as:
                            <CopyBlock
                                text={
                                    "<h1>Static positioning</h1>\n" +
                                    "<div style=\"position: static; width:90px; height:90px; background-color:blue;\"></div>\n" +
                                    "<div style=\"width:100px; height:100px; background-color:red;\"></div>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                showLineNumbers={true}
                                codeBlock
                            />
                            Which will be displayed as:
                            <hr/>
                            <h3>Static positioning</h3>
                            <div style={{width:"90px", height:"90px", backgroundColor:"blue"}}></div>
                            <div style={{width:"100px", height:"100px", backgroundColor:"red"}}></div>
                            <hr/>
                        </li>
                        <li><i>relative</i>: The element is positioned according to the normal flow of the document, and then
                            offset relative to itself based on the values of top, right, bottom, and left.
                            For example, if you set top to 20px, the element will be moved 20px down from its original position.
                            The following code snippet shows how to use relative positioning
                            (pay attention to line 2 and the blue square):
                            <CopyBlock
                                text={
                                    "<h1>Relative positioning</h1>\n" +
                                    "<div style=\"position: relative; top: 10px; bottom: 10px; width:90px; height:90px; background-color:blue;\"></div>\n" +
                                    "<div style=\"width:100px; height:100px; background-color:red;\"></div>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                showLineNumbers={true}
                                codeBlock
                            />
                            Which will be displayed as:
                            <hr/>
                            <h3>Relative positioning</h3>
                            <div style={{position: "relative", top: "25px", left: "30px", width:"90px", height:"90px", backgroundColor:"blue"}}></div>
                            <div style={{width:"100px", height:"100px", backgroundColor:"red"}}></div>
                            <hr/>
                        </li>
                        <li><i>absolute</i>: The element is positioned relative to the nearest positioned ancestor (instead of
                            positioned relative to the viewport, like fixed). However; if an absolute positioned element has no
                            positioned ancestors, it uses the document body, and moves along with page scrolling.
                            For example, the following code snippet shows how to use absolute positioning
                            (pay attention to line 2 and the blue square):
                            <CopyBlock
                                text={
                                    "<h1>Absolute positioning</h1>\n" +
                                    "<div style=\"position: absolute; top: 10px; bottom: 10px; width:90px; height:90px; background-color:blue;\"></div>\n" +
                                    "<div style=\"width:100px; height:100px; background-color:red;\"></div>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                showLineNumbers={true}
                                codeBlock
                            />
                            <br/>
                            We won't show here how it looks like, but you can see it in the video and you can
                            try the previous code snippet in your own file.
                        </li>
                        <li><i>fixed</i>: The element is positioned relative to the viewport, which means it always stays in the
                            same place even if the page is scrolled. This can be useful for things like sidebars and menus.
                            For example, the following code snippet shows how to use
                            fixed positioning (pay attention to line 2 and the blue square):
                            <CopyBlock
                                text={
                                    "<h1>Fixed positioning</h1>\n" +
                                    "<div style=\"position: fixed; top: 10px; bottom: 10px; width:90px; height:90px; background-color:blue;\"></div>\n" +
                                    "<div style=\"width:100px; height:100px; background-color:red;\"></div>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                showLineNumbers={true}
                                codeBlock
                            />
                            <br/>
                        </li>
                    </ul>
                    <h4>Exercise</h4>
                    Write an HTML file that contains:
                    <ul>
                        <li>An h1 title (it will be partially covered by other elements)</li>
                        <li>Under the title a blue squared box</li>
                        <li>On the very top left of the page, with a few pixels from the top, a yellow box</li>
                        <li>Under the blue squared box, misaligned to the right and further down, a green box</li>
                        <li>Just right of the yellow box, a pink box that scrolls down with the page in case the page
                        is small. Give the pink box also some space from the top of the page</li>
                    </ul>
                    At the end, the page should look like this:
                    <br/>
                    <img style={{"maxWidth": "50%"}} src={positionImage} alt={"Position exercise"}/>
                    <h4>Solution</h4>
                    Create a file called <i>position.html</i> and write the following code:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "<head>\n" +
                            "    <title>Position</title>\n" +
                            "</head>\n" +
                            "<body>\n" +
                            "    <h1>Partially hidden title</h1>\n" +
                            "    <div style=\"position: static; width:90px; height:90px; background-color:blue;\"></div>\n" +
                            "    <div style=\"position: absolute; top: 10px; width:90px; height:90px; background-color:yellow;\"></div>\n" +
                            "    <div style=\"position: relative; top: 60px; left: 100px; width:90px; height:90px; background-color:green;\"></div>\n" +
                            "    <div style=\"position: fixed; left: 100px; top: 50px; width:90px; height:90px; background-color:pink;\"></div>\n" +
                            "</body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Position;

