import React from "react";

// reactstrap components
import {
    Card, CardBody, CardTitle,
    Col,
    Container, Row,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CourseHeader from "../../components/Courses/CourseHeader";
import pythonDef from '../../assets/courses/html.json';

class CoursesCatalog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseDef: pythonDef
    };
  }

  componentDidMount() {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("sidebar-collapse");
  }

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
            <CourseHeader>Pacarschool</CourseHeader>
          <div className="section section-about-us">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto mb-4">
                        <Card style={{ borderRadius: 15, backgroundColor: "#f5feff"}}>
                            <CardBody>
                                <CardTitle>
                                    <h2 className="title text-center">Courses Catalog</h2>
                                </CardTitle>
                                <h5 className="description mt-3">
                                    <h5>Courses list:</h5>
                                    <ul>
                                        <li><a href={"./html"}>HTML</a></li>
                                        <li><a href={"./css"}>CSS</a></li>
                                        <li><a href={"./javascript"}>Javascript</a></li>
                                    </ul>
                                </h5>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
          <DefaultFooter />
          </div>
        </div>
      </>
    );
  }
}

export default CoursesCatalog;
