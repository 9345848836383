import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class CommentsStyleAndColor extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Comments, style and color"}
                       description={"Comments in code, tags to change style and color of parts of the page."}>
                <SubLessonJSX lessonIndex={2} subLessonIndex={0}
                              title={"Comments"} youtubeURL={"https://youtu.be/OGkEwGNI9O8"}
                              localVideoPath={"videos/HTML/720p/05_Comments.mp4"}
                >
                    Comments are a part of the html file that the browser will ignore. Comments will not change
                    the way the browser renders your page, but are visible only to people reading the source code.
                    anything starting with <b><i>&lt;!--</i></b> and finishing with <b><i>--&gt;</i></b> will
                    be treated as a comment. For example: <b><i>&lt;!-- This is a comment --&gt;</i></b>. <br/>
                    Comments are useful to explain what is happening in the code or for which reason a part of code
                    was written in a particular way. Another typical scenario when comments are useful is to
                    temporarily comment out part of the code, to try to locate problems or quickly see how the page
                    looks without the commented out lines. <br/>
                    Please be aware that comments should be used sporadically, because in general the code should
                    be self-explanatory and, once the possible problem is located, it's good practice to leave
                    no commented out code in the page.
                    <h4>Exercise</h4>
                    Insert a comment at the beginning of your last html file with the date of today and
                    the name of your school.
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "<!-- 10/10/2021 This file was created as part of the " +
                            "HTML course in my_school_name -->\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website Title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>My Website</h1>\n" +
                            "          <br/>\n" +
                            "          <h2>First sub-paragraph</h2>\n" +
                            "               <p>\n" +
                            "                    <b>Bold word</b>\n" +
                            "               </p>\n" +
                            "          <h2>Second sub-paragraph</h2>\n" +
                            "               <p>\n" +
                            "                    <i>Italic word</i>\n" +
                            "               </p>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />

                    In particular notice line 2:
                    <CopyBlock
                        text={
                            "<!-- 10/10/2021 This file was created as part of the " +
                            "HTML course in my_school_name -->\n"
                        }
                        startingLineNumber={2}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={2} subLessonIndex={1}
                              title={"Style and color"} youtubeURL={"https://youtu.be/eN72Ft-CCg4"}
                              localVideoPath={"videos/HTML/720p/06_StyleAndColor.mp4"}
                >
                    Styling a website is what will distinguish your website from another. It will allow you for
                    example to choose the background color, the text color any many more things. <br/>
                    Note that this lesson will only introduce some basic styling techniques, to allow you
                    to also read existing HTML code with styles. Styling HTML is a big topic and is usually
                    done in another language called CSS (Cascading Style Sheet). Let's not worry about it for now.
                    <br/>
                    <h4>Changing the text color</h4>
                    HTML allows to include a special attribute called <i>style</i> inside HTML tags. The style
                    attribute can define some basic styles for an element. For example:
                    <CopyBlock
                        text={
                            "<p style=\"color: blue;\">Some blue text</p>\n"
                        }
                        startingLineNumber={1}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed like this: <span style={{color: "blue"}}>Some blue text</span><br/>
                    We have a paragraph tag in which we have inserted the <i>style</i> attribute. The part after
                    the <i>style</i> keyword is an actual CSS statement. A CSS statement is constituted as follows:
                    <CopyBlock
                        text={
                            "styleProperty: value;\n"
                        }
                        startingLineNumber={1}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    On the left side there is the name of the property we want to modify and on the right, after
                    the colon the value we want the property to assume. In our case the <i>styleProperty</i> is
                    <i>color</i> and the value is <b>blue</b>. To signal that the value of the property is finished
                    and to separate multiple propertied from each other we end the value with a semicolon. <br/>
                    The color property admits many common color names, try with your favourite color. The property
                    also allows for Hexadecimal values, but we can stick to using names for now. <br/>
                    <h4>Setting a background color</h4>
                    Inside the style tags you can change more than one CSS property value. For example if we want
                    to both change the color and the background color of some text we can combine them: <br/>
                    <CopyBlock
                        text={
                            "<p style=\"color:blue; background-color: yellow;\">" +
                            "Some blue text with yellow background</p>\n"
                        }
                        startingLineNumber={1}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Which will look like this: <span style={{color: "blue", backgroundColor: "yellow"}}>
                        Some blue text with yellow background</span><br/>
                    To do this we used the <i>styleProperty</i> <i>background-color</i>. When you want to set
                    more than one style property you can just write them one after the other separating them
                    with a semicolon.
                    <h4>Nesting styles</h4>
                    What happens when a style is a applied to a container tag, a tag that contains other tags
                    (for example the <i>&lt;body&gt;</i> tag)? <br/>
                    All HTML elements inside the container tag will inherit its the style properties. They
                    still have the opportunity to override or modify the styles by re-defining them. For example:
                    <CopyBlock
                        text={
                            "<body style=\"background-color: lightblue\">\n" +
                            "     <h2 style=\"color: green\">\n" +
                            "          Style and Colors\n" +
                            "     </h2>\n" +
                            "\n" +
                            "     <p style=\"color: red; background-color: blue\">\n" +
                            "          paragraph text\n" +
                            "     </p>\n" +
                            "</body>\n"
                        }
                        startingLineNumber={1}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <div style={{backgroundColor: "lightblue"}}>
                        <h3 style={{color: "green"}}>Style and Colors</h3>
                        <div style={{color: "red", backgroundColor: "blue"}}>paragraph text</div>
                    </div>
                    In this example the body tag defines a background color that is applied both to the
                    <i>&lt;h2&gt;</i> tag and the <i>&lt;p&gt;</i> tag. The <i>&lt;h2&gt;</i> tag further
                    defines the text color to be green, while the <i>&lt;p&gt;</i> tag defines the color to be red
                    and overrides (changes) the background color that would inherit from the <i>&lt;body&gt;</i>
                    tag from lightblue to blue. <br/>
                    If the <i>&lt;p&gt;</i> tag at line 6 didn't redefine the background color:
                    <CopyBlock
                        text={
                            "     <p style=\"color: red;\">\n"
                        }
                        startingLineNumber={6}
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The final result would look like this:
                    <div style={{backgroundColor: "lightblue"}}>
                        <h3 style={{color: "green"}}>Style and Colors</h3>
                        <div style={{color: "red"}}>paragraph text</div>
                    </div>
                    <h4>Exercise</h4>
                    Starting from the following code, make the body have a beige background color, the
                    <i>&lt;h1&gt;</i> title with a green text color, the paragraph <i>&lt;p&gt;</i> have a
                    yellow background color with black text.
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website with colors Title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>This is the titled to be colored green</h1>\n" +
                            "          <hr/>\n" +
                            "          <p>\n" +
                            "               This is the paragraph to be colored black with a yellow background\n" +
                            "          </p>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The final result should look approximately like this:
                    <div style={{backgroundColor: "beige"}}>
                        <h3 style={{color: "green"}}>This is the titled to be colored green</h3>
                        <div style={{color: "black", backgroundColor: "yellow"}}>
                            This is the paragraph to be colored black with a yellow background</div>
                    </div>
                    <br/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website with colors Title</title>\n" +
                            "     </head>\n" +
                            "     <body style=\"background-color: beige\">\n" +
                            "          <h1 style=\"color: green\">This is the titled to be colored green</h1>\n" +
                            "          <hr/>\n" +
                            "          <p style=\"color: black; background-color: yellow\">\n" +
                            "               This is the paragraph to be colored black with a yellow background\n" +
                            "          </p>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default CommentsStyleAndColor;
