import {atomOneLight, CodeBlock, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import lion from "../../../assets/img/lion.jpg";
import zebras from "../../../assets/img/zebras.jpg";

class Images extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Images"}
                       description={"Insert images in the website for more color, information and fun."}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Images"} youtubeURL={"https://youtu.be/u2BtHQwxq7E"}
                              localVideoPath={"videos/HTML/720p/09_Images.mp4"}
                >
                    Almost every website contains images, in the form of logos, photo galleries or banner content.
                    Images help the user understand the content and focus on what's important.
                    <br/>
                    <br/>
                    <h5>The Image Tag</h5>
                    HTML uses a the tag <i>img</i> to embed images in our website. For example:
                    <CopyBlock
                        text={
                            "<img src=\"./images/lion.jpg\" />"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    Which will be displayed as:
                    <hr/>
                    <img src={lion} alt={"a lion"}/>
                    <hr/>
                    The <i>img</i> tag is a non-container tag, so it doesn't require a start and end component, since
                    it doesn't contain text or other tags. We can pass various attibutes to this tag, most importantly
                    <i>src, width, height, alt</i>. Let's look at them individually.
                    <br/>
                    <br/>
                    <h6>Src tag</h6>
                    The src attribute where to fetch the image, in other words where the source file is located.
                    It can either be an URL (link), in which case the image is taken directly from the internet, or a
                    relative path which points to a location in the local filesystem where the file is located.
                    This is the case for the lion image we just displayed. <br/>
                    Usually, when adding an image from the local filesystem, it is placed in an image folder (directory)
                    in order to keep files organized. To add a local image thus you should:
                    <ol>
                        <li>Add or copy the file to the images folder inside the main folder of your website</li>
                        <li>Fill the <i>src</i> attribute with the relative path, from the location of your html file
                        to the image itself. In our case it was <i>src="./images/lion.jpg"</i></li>
                    </ol>
                    Our folder structure will look like this:
                    <CodeBlock
                        text={
                            "- website/ \n" +
                            "       - index.html \n" +
                            "       - images/ \n" +
                            "               - lion.jpg \n"
                        }
                        showLineNumbers={false}
                        language={"bash"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The "<i>./</i>" (dot forward slash) points to the current directory, so the directory in which
                    <i>index.html</i> is located. <br/>
                    The <i>src</i> attribute is mandatory in any HTML <i>img</i> tag, otherwise the browser wouldn't
                    know where to find the image that needs to be displayed.
                    <br/>
                    <br/>
                    <h6>Width and Height</h6>
                    If the <i>width</i> or the <i>height</i> tags are not specified, then the image is displayed in its
                    original size, expressed in pixel. A pixel is one of the little dots that are lit and make up
                    the screen of the computer. Given that screens have different resolutions, a certain amount of
                    pixels may differ in actual size on different screens. <br/>
                    To change the size of the image, specify either a new <i>width</i> or a new <i>height</i>. HTML
                    will automatically take care of the other dimension, maintaining the aspect ration of the image or
                    in other words making sure the image is not stretched in either direction. <br/>
                    For example we can display our lion image much smaller than before, by specifying a width:
                    <CopyBlock
                        text={
                            "<img src=\"./images/lion.jpg\" width=\"100\"/>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    Which will be displayed as:
                    <hr/>
                    <img src={lion} width={100} alt={"a lion"}/>
                    <hr/>
                    <br/>
                    <h6>Alt</h6>
                    The <i>alt</i> attribute is used to give the image a text description. This is useful in three
                    reasons:
                    <ul>
                        <li>in case the image is not found and cannot be loaded, for example because it's not
                        found on the local filesystem</li>
                        <li>for search engines like google, that can mostly only search inside text content</li>
                        <li>in case the website is accessed by someone with vision impairment. There are in fact
                        software that can read out loud a website; these software read the <i>alt</i> attribute
                        of the <i>img</i> tag.</li>
                    </ul>
                    It is thus always important and mandatory to put the <i>alt</i> attribute inside
                    every <i>img</i> tag.
                    <h4>Exercise</h4>
                    Create an HTML page where you have two images: one of the lion and one of two zebras. Place the
                    images under the title with the name of the corresponding animal. Pay attention that both images
                    should have the same width in order for the page to have a clean look. <br/>
                    You can download the image of the lion <a href={lion} download={"lion.jpg"}>here</a> and the one
                    of the two zebras <a href={zebras} download={"zebras.jpg"}>here</a>.
                    <h4>Solution</h4>
                    You should have the following folder structure:
                    <CodeBlock
                        text={
                            "- website/ \n" +
                            "       - index.html \n" +
                            "       - images/ \n" +
                            "               - lion.jpg \n" +
                            "               - zebras.jpg \n"
                        }
                        showLineNumbers={false}
                        language={"bash"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    And inside <i>index.html</i> you should have a code similar to:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>Two different animals</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "           <h2>A lion</h2>\n" +
                            "           <img width=\"300\" src=\"./images/lion.jpg\" alt=\"a lion\">\n" +
                            "           <h2>Two zebras</h2>\n" +
                            "           <img width=\"300\" src=\"./images/zebras.jpg\" alt=\"two zebras\">\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Images;
