import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";


class CSSFrameworks extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"CSS frameworks"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"CSS frameworks"}
                              youtubeURL={"https://youtu.be/nDXDxA27poE"}
                              localVideoPath={"videos/CSS/720p/17_CSSFrameworks.mp4"}
                >
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default CSSFrameworks;

