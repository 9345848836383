import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class JSObjects extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Objects"}
                       description={""}>
                        {/* TODO: description */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Creating and using objects"} youtubeURL={"https://youtu.be/EBjQytpLt0o"}
                              localVideoPath={""}
                >
                    An object is a type of data that can be used to store other types of data. Here is an example of how it 
                    is created: 
                    <CopyBlock
                            text={
                                "           var student = {\n"+
                                "               name: \"Peter\",\n"+
                                "               age: 18,\n"+
                                "               major: \"Computer Science\"\n"+
                                "           };\n"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    To alter any propriety you have to access it by using his name, dot, and the sub-data name:
                    <CopyBlock
                            text={
                                "           student.age = 19;"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    Also, to print an element you can access it in the same way:
                    <CopyBlock
                            text={
                                "           document.write( student.name );"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={7} subLessonIndex={1}
                              title={"Real life objects"} youtubeURL={"https://youtu.be/u4b0sL8jRbU"}
                              localVideoPath={""}
                >
                    Objects can also be used to represent objects in real life. To create more complex structures is possible
                    to add an object inside of another, as in the following example.
                    <CopyBlock
                            text={
                                "           var myMovie = {\n"+
                                "               title: \"The Social Network\",\n"+
                                "               releaseYear: \"2010\",\n"+
                                "               duration: 2.0,\n"+
                                "               actors: [\n"+
                                "                   {\n"+
                                "                       name: \"Jessie Eisenberg\",\n"+
                                "                       birthday: new Date(\"October 5, 1983\"),\n"+
                                "                       hometown: \"New York, New York\"\n"+
                                "                    },\n"+
                                "                    {\n"+
                                "                       name: \"Rooney Mara\",\n"+
                                "                       birthday: new Date(\"April 17, 1985\"),\n"+
                                "                       hometown: \"Bedford New York\"\n"+
                                "                    }\n"+
                                "               ]\n"+
                                "           };\n"

                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    The sub-objects are accessed as a vector using one of the previous examples.
                    <CopyBlock
                            text={
                                "           document.write( myMovie.actors[0].birthday );"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default JSObjects;