import React from "react";

// reactstrap components
import ReactPlayer from "react-player";

// core components
const tryRequire = (path) => {
    try {
        return require(`../../../public/${path}`);
    } catch (err) {
        console.log(err)
        console.log("Require unsuccesful")
        console.log(path)
        return null;
    }
};

class VideoLocalOrYoutube extends React.Component {
    render() {
        return ( <>
                <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}>
                {tryRequire(this.props.localPath) ?
                    <ReactPlayer url={this.props.localPath}
                                 height={"100%"} width={"100%"}
                                 controls={true}
                    /> :
                    (this.props.youtubeURL ?
                            <ReactPlayer url={this.props.youtubeURL}/> :
                            null
                    )

                }
            </div>
            </>
        );
    }
}

export default VideoLocalOrYoutube;
