import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class InputAndCalculator extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Getting an input and creating a calculator"}
                       description={""}>
                        {/* TODO: description */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"User input"} youtubeURL={"https://youtu.be/15oNAHVuwIE"}
                              localVideoPath={""}
                >
                    Input is a way you can get data from the user of your program or website. One way to do this is by using the 
                    window prompt and storing the information in a variable.
                    <CopyBlock
                        text={
                            "               var numberOfStudents = window.prompt(\"How many students are taking this class?\");\n" +
                            "               document.write(\"There are \" + numberOfStudents + \" students in this class.\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    When it is stored, the type of data is a string. It can be used as it or converted to an integer with the function <i>parseInt();</i> .
                    <CopyBlock
                        text={
                            "               var numberOfStudents = window.prompt(\"How many students are taking this class?\");\n" +
                            "               numberOfStudents = parse.Int(numberOfStudents);\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Building a calculator"} youtubeURL={"https://youtu.be/opz_-qeDi2E"}
                              localVideoPath={""}
                >
                    The first step to build the calculator is to get the input of the numbers. It can be done using the <i>window.prompt</i> and stored
                    in the variable. Don't forget to use the <i>parseInt();</i> function to get a functional number.<br/>
                    After that, you can print the result of the operation. Here is an example of addition:
                    <CopyBlock
                        text={
                            "               var number1 = window.prompt(\"First number: \");\n" +
                            "               var number2 = window.prompt(\"Second number: \");\n" +
                            "               number1 = parse.Int(number1);\n" +
                            "               number2 = parse.Int(number2);\n" +
                            "                                            \n" +
                            "               document.write(number1 + number2);\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <h4>Exercise</h4>
                    After the addition operation, display other operations as subtraction, multiplication, division, exponentiation and the result
                    of the division.
                    <br/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "               var number1 = window.prompt(\"First number: \");\n" +
                            "               var number2 = window.prompt(\"Second number: \");\n" +
                            "               number1 = parse.Int(number1);\n" +
                            "               number2 = parse.Int(number2);\n" +
                            "                                            \n" +
                            "               document.write(number1 + number2); //addition\n" +
                            "               document.write(number1 - number2); //subtraction\n" +
                            "               document.write(number1 * number2); //multiplication\n" +
                            "               document.write(number1 / number2); //division\n" +
                            "               document.write(number1 ** number2); //exponentiation\n" +
                            "               document.write(number1 % number2); //remainder of the division\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default InputAndCalculator;
