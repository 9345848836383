import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class Quiz extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Building A Quiz"}
                       description={"Activity"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Multiple choice test"} youtubeURL={"https://youtu.be/LQGTb112N_c"}
                              localVideoPath={""}
                >
                The questions will be created using an array of objects that will each one.
                Here is an example of how the structure goes:
                <CopyBlock
                    text={
                        "               var questions = [\n"+
                        "                   {\n"+
                        "                       prompt: \"What color are apples? (a) Red/Green (b) Purple (c) Orange,\"\n"+
                        "                       answer: \"a\"\n"+
                        "                   },\n"+
                        "                   {\n"+
                        "                       prompt: \"What color are Bananas? (a) Teal (b) Magenta (c) Yellow\",\n"+
                        "                       answer: \"c\"\n"+
                        "                   },\n"+
                        "                   {\n"+
                        "                       prompt: \"What color are strawberries? (a) Yellow  (b) Red  (c) Blue\",\n"+
                        "                       answer: \"b\"\n"+
                        "                   }\n"+
                        "               ];"
                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                After that, create a variable that will store how many points the user has.
                <CopyBlock
                    text={
                        "               var score = 0;"
                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                The last part is to display the questions and how many of them were correct. The following code uses a 'for' to print
                in sequence the elements of the array, using the index 'i', and get the answer from the user. If it is right, it prints
                "Correct", adding the counter; in another case, it prints "Wrong". At the end, it displays how many questions were 
                answered correctly. 
                <CopyBlock
                    text={
                        "               for(var i = 0; i < questions.length; i++){\n"+
                        "                   var response = window.prompt(questions[i].prompt);\n"+
                        "                   if(response == questions[i].answer){\n"+
                        "                       score++;\n"+
                        "                       alert(\"Correct!\");\n"+
                        "                   } else {\n"+
                        "                       alert(\"WRONG!\");\n"+
                        "                   }\n"+
                        "               }\n"+
                        "               \n"+
                        "               alert(\"you got \" + score + "/" + questions.length);"
                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Quiz;
