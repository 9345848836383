import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class TwoDArrays extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Two Dimensions Array"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"2d Arrays and Nested Loops"} youtubeURL={"https://youtu.be/LQGTb112N_c"}
                              localVideoPath={""}
                >
                A 2 dimensions array is basicaçy an array of arrays. Here is an example of how it is created:
                <CopyBlock
                    text={
                        "               var numbers = [\n"+
                        "                   [1, 2, 3],\n"+
                        "                   [4, 5, 6],\n"+
                        "                   [7, 8, 9],\n"+
                        "                   [0];\n"+
                        "               ];"

                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                To access one item you will need two parameters for each array, the number of the array and the element inside of it:
                <CopyBlock
                    text={
                        "               numbers[i][j];"

                    }
                    language={"javascript"}
                    theme={atomOneLight}
                    codeBlock
                />
                If you need to print all the elements, a good strategy is to use a nested loop.
                <br/>
                A nested loop is a way to create a loop inside of another. The most common way is to use two 'fors'. 
                Every time one interaction of the outside happens, all the interactions of the inside happen.  
                <CopyBlock
                        text={
                            "               for(var i=0; i<=2; i++){\n"+
                            "                   for(var j=0; j<=2; j++){\n"+
                            "                       document.write( i + \" \" + j + \" - \");\n"+
                            "                   }\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                The output will be: 
                0 0 - 0 1 - 0 2 - 1 0 - 1 1 - 1 2 - 2 0 - 2 1 - 2 2 -
                <br/>
                Here is how you can print all numbers from the arrays:
                <CopyBlock
                        text={
                            "               numberGrid = [ [1, 2], [3, 4] ];\n"+
                            "                                               \n"+
                            "               for(var i = 0; i < numberGrid.length; i++){\n"+
                            "                   for(var j = 0; j < numberGrid[i].length; j++){\n"+
                            "                       document.write(numberGrid[i][j] + \", \");\n"+
                            "                   }\n"+
                            "                   document.write(\"<br>\");\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                This is the output:
                1, 2,
                3, 4,
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"For Each Loops"} youtubeURL={"https://youtu.be/Au7JCqkVq9M"}
                              localVideoPath={""}
                >
                One easiest way to print all the elements from the array is to use a javascript function called "forEach";
                Here is an example of how it works:
                <CopyBlock
                        text={
                            "               var luckyNums = [4, 8, 15, 16, 23, 42];\n"+
                            "                                               \n"+
                            "               luckyNums.forEach(function(luckyNum){\n"+
                            "                   document.write(luckyNum + \"<br>\");\n"+
                            "               });"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                 The output will be:
                 4
                 8
                 15
                 16
                 23
                 42
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={2}
                              title={"For Each Loops"} youtubeURL={"https://youtu.be/Au7JCqkVq9M"}
                              localVideoPath={""}
                >
                One easiest way to print all the elements from the array is to use a javascript function called "forEach";
                Here is an example of how it works:
                <CopyBlock
                        text={
                            "               var luckyNums = [4, 8, 15, 16, 23, 42];\n"+
                            "                                               \n"+
                            "               luckyNums.forEach(function(luckyNum){\n"+
                            "                   document.write(luckyNum + \"<br>\");\n"+
                            "               });"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                 />
                 The output will be:
                 4
                 8
                 15
                 16
                 23
                 42
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default TwoDArrays;
