import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CodeBlock} from "react-code-blocks";
import cssLogo from "../../../assets/img/css_logo.png";
import lion from "../../../assets/img/lion.jpg";

class ShadowsBorderRadiusAndTextFonts extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Shadows, Opacity, Border Radius, Text and Fonts"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Shadows, Opacity, Border Radius"}
                              youtubeURL={"https://youtu.be/kZAlp-MDvlI"}
                              localVideoPath={"videos/CSS/720p/07_ShadowsOpacityAndBorderRadius.mp4"}
                >
                    A few interesting attributes can be used to add shadows, opacity and border radius to elements. This
                    may help readability and make your website look more professional.
                    <ul>
                        <li><i>opacity</i>: This attribute can be used to make an element transparent. The value can be
                            between 0 and 1, where 0 is completely transparent and 1 is completely opaque.
                            For example, <code>opacity: 0.5;</code> will make an element 50% transparent.
                        </li>
                        <li><i>text-shadow</i>: This attribute can be used to add a shadow to text. The value is a
                            comma-separated list of shadows. Each shadow is defined by the following properties:
                            <ul>
                                <li><i>horizontal offset</i>: The horizontal offset of the shadow. This can be a
                                    positive or negative number.
                                </li>
                                <li><i>vertical offset</i>: The vertical offset of the shadow. This can be a
                                    positive or negative number.
                                </li>
                                <li><i>blur radius</i>: The blur radius of the shadow. This can be a positive number
                                    and indicates how blurry the shadow should be.

                                </li>
                            </ul>
                            For example, <code>text-shadow: 2px 2px 5px red;</code> will add a red shadow to the
                            text with a horizontal offset of 2px, a vertical offset of 2px and a blur radius of 5px.
                        </li>
                        <li><i>box-shadow</i>: it acts
                            the same as the text-shadow attribute, but it adds a shadow to the element instead of the
                            text.
                        </li>
                        <li><i>border-radius</i>: This attribute can be used to add rounded corners to an element. The
                            value can either be the number of pixels to round the corners by or a percentage of the
                            element's width and height. For example, <code>border-radius: 10px;</code> will round the
                            corners by 10 pixels and <code>border-radius: 50%;</code> will round the corners by 50% of
                            the element's width and height, which will make it look like a circle.
                        </li>
                    </ul>
                    For example the following code:
                    <CodeBlock
                        text={
                            "<h1 style=\"text-shadow: 2px 2px 2px red;  \n" +
                            "           position: relative;\n" +
                            "           top:125px;\n" +
                            "           left:15px\">\n" +
                            "     CSS Tutorial\n" +
                            "</h1>\n" +
                            "\n" +
                            "<img style=\"opacity:0.4;\n" +
                            "            border-radius: 50%;\"\n" +
                            "     src=\"logo.jpeg\"\n" +
                            "     alt=\"CSS3 logo\"\n" +
                            "/>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                    will produce the following output:
                    <hr/>
                    <h4 style={{
                        textShadow: "2px 2px 2px red",
                        position: "relative",
                        top: "125px",
                        left: "15px"
                    }}>
                        CSS Tutorial
                    </h4>
                    <img style={{
                        opacity: "0.4",
                        borderRadius: "50%",
                        width: "30%",
                    }}
                            src={cssLogo}
                            alt={"CSS3 logo"}
                    />
                    <hr/>
                    <h4>Exercise</h4>
                    Given the image of a lion, that can be downloaded from <a href={lion} download={"lion.jpg"}>here</a>,
                    {" "}display it on the page with 50% opacity, borders slightly rounded and a shadow.
                    It should look something like this:
                    <hr/>
                    <img style={{
                        opacity: "0.5",
                        borderRadius: "20px",
                        boxShadow: "6px 6px 2px black",
                        width: "30%",
                    }}
                         src={lion}
                            alt={"lion"}
                    />
                    <hr/>
                    <h4>Solution</h4>
                    <CodeBlock
                        text={
                            "<img style=\"opacity:0.5;\n" +
                            "            border-radius: 20px;\n" +
                            "            box-shadow: 6px 6px 2px black;\"\n" +
                            "     src=\"lion.jpg\"\n" +
                            "     alt=\"lion\"\n" +
                            "/>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Text and fonts"}
                              youtubeURL={"https://youtu.be/Bc6tss3gEvU"}
                    localVideoPath={"videos/CSS/720p/08_TextAndFonts.mp4"}
                >
                    There are many attributes that can be used to style text. The following are some of the most
                    commonly used attributes:
                    <ul>
                        <li><i>font-family</i>: This attribute can be used to change the font of text.
                            The value is a comma-separated list of fonts. The browser will use the first font
                            that is available on the user's computer. If none of the fonts are available,
                            the browser will use the default font. For example, <code>font-family: "Times New Roman",
                            Times, serif;</code> will change the font to Times New Roman, but if that font is not
                            available, it will use Times and if that font is not available, it will use the default
                            serif font.
                        </li>
                        <li><i>font-size</i>: This attribute can be used to change the size of text. The value can be
                            a number followed by a unit. The most commonly used units are <i>px</i> (pixels) and
                            <i>em</i> (relative to the font size of the parent element). For example, <code>font-size:
                            20px;</code> will change the font size to 20 pixels and <code>font-size: 1.5em;</code> will
                            change the font size to 1.5 times the font size of the parent element.
                            It is recommended to use <i>em</i> instead of <i>px</i> because it will make your website
                            more responsive.
                        </li>
                        <li><i>font-style</i>: This attribute can be used to change the style of text. The value can be
                            <i>normal</i> or <i>italic</i>. For example, <code>font-style: italic;</code> will make the
                            text italic.
                        </li>
                        <li><i>font-weight</i>: This attribute can be used to change the weight of text. The value can
                            be a number between 100 and 900 or the keywords <i>normal</i> and <i>bold</i>. For example,{" "}
                            <code>font-weight: 700;</code> will make the text bold and <code>font-weight: normal;</code>{" "}
                            will make the text normal weight.
                        </li>
                        <li>
                            <i>font-variant</i>: This attribute can be used to change the variant of text. The value can
                            be <i>normal</i> or <i>small-caps</i>. For example, <code>font-variant: small-caps;</code>{" "}
                            will make the text small-caps. Small-caps is a variant of text all the text is displayed as
                            capital, but the first letters are bigger than the rest of the text.
                        </li>
                        <li><i>text-align</i>: This attribute can be used to align text. The value can be <i>left</i>,
                            <i>right</i>, <i>center</i> or <i>justify</i>. For example, <code>text-align: center;</code>{" "}
                            will center the text.
                        </li>
                        <li><i>text-decoration</i>: This attribute can be used to add a decoration to text. The value
                            can be <i>none</i>, <i>underline</i>, <i>overline</i> or <i>line-through</i>. For example,
                            <code>text-decoration: underline;</code> will underline the text.
                        </li>
                    </ul>
                    <h4>Exercise</h4>
                    Write an HTML file with two paragraphs of text. The first paragraph should be centered, bold, use
                    "Ariel Black" and have a font size that is twice the font size of the parent element. The second
                    paragraph should be left-aligned, italic, use "Times New Roman" and have a font size that is 1.5
                    times the font size of the parent element. <br/>
                    It should look like this:
                    <hr/>
                    <p style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Ariel Black",
                        fontSize: "2em",
                    }}
                    >
                        This is a centered, bold, Ariel Black paragraph.
                    </p>
                    <p style={{
                        textAlign: "left",
                        fontStyle: "italic",
                        fontFamily: "Times New Roman",
                        fontSize: "1.5em",
                    }}
                    >
                        This is a left-aligned, italic, Times New Roman paragraph.
                    </p>
                    <hr/>
                    <h4>Answer</h4>
                    <CodeBlock
                        text={
                            "<p style=\"text-align: center;\n" +
                            "   font-weight: bold;\n" +
                            "   font-family: 'Ariel Black';\n" +
                            "   font-size: 2em;\">\n" +
                            "   This is a centered, bold, Ariel Black paragraph.\n" +
                            "</p>\n" +
                            "\n" +
                            "<p style=\"text-align: left;\n" +
                            "   font-style: italic;\n" +
                            "   font-family: 'Times New Roman';\n" +
                            "   font-size: 1.5em;\">\n" +
                            "   This is a left-aligned, italic, Times New Roman paragraph.\n" +
                            "</p>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        showLineNumbers={true}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default ShadowsBorderRadiusAndTextFonts;

