import React from "react";

// reactstrap components
import {Container } from "reactstrap";

// core components

class CourseHeader extends React.Component {
  constructor(props) {
    super(props);
    this.pageHeader = React.createRef();
  }

  componentDidMount() {
    if (window.innerWidth > 991) {
      this.updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        this.pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", this.updateScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateScroll);
  }

  render() {
    return (
        <>
          <div className="page-header page-header-small"
               style={{
                   minHeight: "10vh",
                   backgroundColor: "#00fbff"
               }}>
            <div
                className="page-header-image"
                style={{
                  // backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")",
                    minHeight: "10vh"
                }}
                ref={this.pageHeader}
            ></div>
            <div className="content-center">
              <Container>
                <h1 className="title">{this.props.children !== undefined ? this.props.children : this.props.title}</h1>
              </Container>
            </div>
          </div>
        </>
    );
  }
}

export default CourseHeader;
