import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";


class FlexBox extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Flexbox"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Flexbox"}
                              youtubeURL={"https://youtu.be/6FedyF0F2i8"}
                              localVideoPath={"videos/CSS/720p/13_Flexbox.mp4"}
                >
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default FlexBox;

