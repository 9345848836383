import {atomOneLight, CodeBlock, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import lion from "../../../assets/img/lion.jpg";
import zebras from "../../../assets/img/zebras.jpg";
import lionRoaring from "../../../assets/video/lion_roaring.mp4";

class Videos extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Videos"}
                       description={"Insert images in the website for more color, information and fun."}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Videos"} youtubeURL={"https://youtu.be/M_vEI64hbso"}
                              localVideoPath={"videos/HTML/720p/10_VideosAndYoutubeiFrames.mp4"}
                >
                    Another very common piece of websites are videos, like the ones we are using as lessons on this
                    page. You can embed videos in your websites very similarly to the way you embed images.
                    Instead of using the <i>img</i> tag you'll use the <i>video</i> tag. <br/>
                    The most basic use of it is:
                    <CopyBlock
                        text={
                            "<video src=\"./videos/lion_roaring.mp4\" />"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    Which will be displayed as:
                    <hr/>
                    <video src={lionRoaring}/>
                    <hr/>
                    As you can notice though, there are no controllers and it's impossible to make the video play.
                    To add the controls you can add the <i>controls</i> attribute like this:
                    <CopyBlock
                        text={
                            "<video src=\"./videos/lion_roaring.mp4\" controls />"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    Which will be displayed as:
                    <hr/>
                    <video src={lionRoaring} controls/>
                    <hr/>
                    There are also a few additional attributes that can be useful:
                    <ul>
                        <li>
                            <i>width</i> and <i>height</i>: similarly to images they allow you to set the
                        size of the video. If you set only one of them, the other one will adjust to keep the
                        aspect ratio.
                        </li>
                        <li>
                            <i>autoplay</i>: add this attribute to make the video play automatically when the page is
                            loaded.
                        </li>
                        <li>
                            <i>loop</i>: make the video loop infinitely. When the video is finished, it will be
                            played again from the start
                        </li>
                        <li>
                            <i>poster="image.jpg"</i> allows to set an image that is displayed instead of the video
                            when the video is not playing. This is an image that the user will see until he or she
                            clicks play.
                        </li>
                    </ul>
                    <h4>Exercise</h4>
                    Create an HTML page where you have the video of the lion, resized to be a little smaller than the
                    original. The video should display as poster our usual image of the lion and should loop
                    once it's over. <br/>
                    You can download the image of the lion <a href={lion} download={"lion.jpg"}>here</a> and the video
                    of the roaring lion <a href={lionRoaring} download={"lion_roaring.mp4"}>here</a>.
                    <h4>Solution</h4>
                    You should have the following folder structure:
                    <CodeBlock
                        text={
                            "- website/ \n" +
                            "       - index.html \n" +
                            "       - images/ \n" +
                            "               - lion.jpg \n" +
                            "       - videos/ \n" +
                            "               - lion_roaring.mp4 \n"
                        }
                        showLineNumbers={false}
                        language={"bash"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    And inside <i>index.html</i> you should have a code similar to:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html>\n" +
                            "     <head>\n" +
                            "          <title>A looping video of a lion</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "           <h2>A roaring lion</h2>\n" +
                            "               <video src=\"./videos/lion_roaring.mp4\" \n" +
                            "                  loop \n" +
                            "                  controls \n" +
                            "                  width=\"300\" \n" +
                            "                  poster=\"./images/lion.jpg\"> \n" +
                            "               </video> \n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Videos;
