import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CourseHeader from "../../components/Courses/CourseHeader";
import pythonDef from '../../assets/courses/python.json';
import ReactPlayer from "react-player"
import Lesson from "../../components/Courses/Lesson";

class Python extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseDef: pythonDef
    };
  }

  componentDidMount() {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("sidebar-collapse");
  }

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <CourseHeader title={this.state.courseDef.title} />
          <div className="section section-about-us">
            <Container>
              {this.state.courseDef.lessons.map((lesson, lessonIndex) => {
                console.log(lesson);
                return (<Lesson lessonIndex={lessonIndex} lesson={lesson}/>);
              })}
            </Container>
          <DefaultFooter />
          </div>
        </div>
      </>
    );
  }
}

export default Python;
