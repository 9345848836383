import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import CourseHeader from "../../components/Courses/CourseHeader";
import pythonDef from '../../assets/courses/html.json';
import CourseIntro from "../../components/Courses/CourseIntro";
import { HashLink } from 'react-router-hash-link';
import CssIntro from "./CssLessons/CssIntro";
import ColorsMarginsAndPaddings from "./CssLessons/ColorsMarginsAndPaddings";
import DisplayAndFloats from "./CssLessons/DisplayAndFloats";
import Position from "./CssLessons/Position";
import ShadowsBorderRadiusAndTextFonts from "./CssLessons/ShadowsBorderRadiusAndTextFonts";
import StyleTagsAndExternalStyleSheets from "./CssLessons/StyleTagsAndExternalStyleSheets";
import IDsAndClasses from "./CssLessons/IDsAndClasses";
import CSSSelectors from "./CssLessons/CSSSelectors";
import FlexBox from "./CssLessons/FlexBox";
import Animations from "./CssLessons/Animations";
import ImportsAndBrowserSupport from "./CssLessons/ImportsAndBrowserSupport";
import CSSFrameworks from "./CssLessons/CSSFrameworks";


const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

class Css extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      courseDef: pythonDef
    };
  }

  componentDidMount() {
      document.body.classList.add("landing-page");
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    document.body.classList.remove("sidebar-collapse");
  }

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
            <CourseHeader>Css</CourseHeader>
          <div className="section section-about-us">
            <Container>
                <CourseIntro>
                    Now that we've learned some of the basics of creating web content, we'll spend this term learning
                    cascading style sheets (CSS) to enhance content allowing us to control aspects of the entire web
                    page by specifying the fonts, colors, spacing, and other characteristics that establish a unique
                    look for a web site.
                    <br/>
                    CSS styles can be stored directly in an HTML web page or in a separate style sheet file.
                    Either way, style sheets contain style rules that apply styles to HTML elements of a given type.
                    An internal style sheet is placed directly within a web page HTML code. When used externally
                    instead, style sheet rules are placed in an external style sheet document with
                    the file extension .css and then imported from the HTML file via a special tag.
                    <br/>
                    CSS will allow you to make your website feel even more yours.
                    <br/><br/>
                    The videos of the course are taken, with consent, from <a
                    href={"https://www.mikedane.com/web-development/css/"} target="_blank">Mike Dane</a>.
                     We thank him for his contribution.<br/><br/>
                    <h5>Lessons list:</h5>
                    <HashLink smooth to={"#lesson1"} scroll={scrollWithOffset}>
                        Lesson 1 - Introduction and basic inline styling
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson2"} scroll={scrollWithOffset}>
                        Lesson 2 - Colors, margins and paddings
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson3"} scroll={scrollWithOffset}>
                        Lesson 3 - Display and floats
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson4"} scroll={scrollWithOffset}>
                        Lesson 4 - Position
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson5"} scroll={scrollWithOffset}>
                        Lesson 5 - Shadows, border radius and text fonts
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson6"} scroll={scrollWithOffset}>
                        Lesson 6 - Style tags and external style sheets
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson7"} scroll={scrollWithOffset}>
                        Lesson 7 - IDs and classes
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson8"} scroll={scrollWithOffset}>
                        Lesson 8 - CSS selectors
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson9"} scroll={scrollWithOffset}>
                        Lesson 9 - Flexbox
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson10"} scroll={scrollWithOffset}>
                        Lesson 10 - Animations
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson11"} scroll={scrollWithOffset}>
                        Lesson 11 - Imports and browser support
                    </HashLink><br/>
                    <HashLink smooth to={"#lesson12"} scroll={scrollWithOffset}>
                        Lesson 12 - CSS frameworks
                    </HashLink><br/>
                </CourseIntro>
                <CssIntro lessonIndex={0}/>
                <ColorsMarginsAndPaddings lessonIndex={1}/>
                <DisplayAndFloats lessonIndex={2}/>
                <Position lessonIndex={3}/>
                <ShadowsBorderRadiusAndTextFonts lessonIndex={4}/>
                <StyleTagsAndExternalStyleSheets lessonIndex={5}/>
                <IDsAndClasses lessonIndex={6}/>
                <CSSSelectors lessonIndex={7}/>
                <FlexBox lessonIndex={8}/>
                <Animations lessonIndex={9}/>
                <ImportsAndBrowserSupport lessonIndex={10}/>
                <CSSFrameworks lessonIndex={11}/>
            </Container>
          <DefaultFooter />
          </div>
        </div>
      </>
    );
  }
}

export default Css;
