import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class ArraysAndFunctions extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Arrays and functions"}
                       description={""}>
                        {/* TODO: description */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Arrays"} youtubeURL={"https://youtu.be/JQ-wmaarWTQ"}
                              localVideoPath={""}
                >
                    An array is a variable that can store multiple values of different types. There are two main types to define one:
                    <CopyBlock
                        text={
                            "               const sonsAndAge = [\"Luke\", 26, \"Mark\", 34, \"Tom\", 38];    \n" +
                            "               const sons = new Array (\"Luke\", 26, \"Mark\", 34, \"Tom\", 38);    \n"
                        } 
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    You can also define the values after creating the array. The elements are referenced by the name of the array and your 
                    position on it, starting from the number 0.
                    <CopyBlock
                        text={
                            "               const sons = [];    \n" +
                            "               sons[0] = \"Luke\"; \n" +
                            "               sons[1] = \"Mark\"; \n" +
                            "               sons[3] = \"Tom\";  \n" 
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    There are also some proprieties of the array that can be explored, here are some of them:
                    <ul>
                        <li>array.length: get the number of elements;</li>
                        <li>variable.split(,): transform the variable type string to an array with the elements that were separated by a comma;</li>
                        <li>array.toString(): make the opposite, transforming the array into a string;</li>
                        <li>array.join(" , "): is just like the previous but you can specify what will separate the elements;</li>
                        <li>array.push(element): add a new element to the end of the array;</li>
                        <li>array.pop(): remove the last element;</li>
                    </ul>
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Functions"} youtubeURL={"https://youtu.be/MMC8FUggB7s"}
                              localVideoPath={""}
                >
                    A function is a group of code that can perform a specific task and can be used anytime without repeating the code.<br/>
                    It is created like the example below:
                    <CopyBlock
                        text={
                            "               function name(parameter1, parameter2){\n" +
                            "                                                     \n" +
                            "               }                                     \n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Before the parenthesis, you can set the name of the function and, inside the curly brackets,
                    you can add the code that will
                    be executed when the function is called. This can be made using its name:
                    <i>name(parameter1, parameter2);</i> .
                    The parameters, inside the parenthesis, are variables that receive the value of external ones
                    (arguments) and are used to execute
                    the function.
                    <h4>Exercise</h4>
                    Recreate the calculator app using functions to get the results of the operations.
                    <br/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "               var number1 = window.prompt(\"First number: \");\n" +
                            "               var number2 = window.prompt(\"Second number: \");\n" +
                            "               number1 = parse.Int(number1); \n" +
                            "               number2 = parse.Int(number2); \n" +
                            "                                             \n" +
                            "               addition(number1, number2);   \n" +
                            "               subtraction(number1, number2);\n" +
                            "               multiplication(number1, number2);       \n" +
                            "               division(number1, number2);             \n" +
                            "               exponentiation(number1, number2);       \n" +
                            "               resultOfDivision(number1, number2);     \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function addition(n1, n2){     \n" +
                            "                   return n1 + n2;           \n" +
                            "               }                             \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function subtraction(n1, n2){  \n" +
                            "                   return n1 - n2;           \n" +
                            "               }                             \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function multiplication(n1, n2){\n" +
                            "                   return n1 * n2;           \n" +
                            "               }                             \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function division(n1, n2){     \n" +
                            "                   return n1 / n2;           \n" +
                            "               }                             \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function exponentiation(n1, n2){\n" +
                            "                   return n1 ** n2;           \n" +
                            "               }                             \n" +
                            "                                             \n" +
                            "                                             \n" +
                            "               function resultOfDivision(n1, n2){\n" +
                            "                   return n1 % n2;           \n" +
                            "               }                             \n"          
                        }         
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />

                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default ArraysAndFunctions;
