import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class MessagingApp extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Practicing HTML and JavaScript together"}
                       description={"Using JavaScript And HTML to create a simple messaging app"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Building a messaging app"} youtubeURL={"https://youtu.be/ZOOrhOJQ4q0"}
                              localVideoPath={""}
                >
                   To create a basic messaging app you need first to create separate files for the HTML part and the JavaScript.
                   For the HTML, you will use just four main tags:
                   <CopyBlock
                            text={
                                "            <ul id=\"messages\"></ul>\n"+
                                "            <input id=\"textbox\" type=\"text\">\n"+
                                "            <button id=\"button\">Send</button>\n"+
                                "            <script src=\"script.js\"></script>"
                            }
                            language={"html"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    The unordered list will be used to display the messages, the button to send, and the textbox to write them.
                    The script tag makes the connection between the files. Don't forget to fill in all the basic tags of the HTML document.
                    It's very important to pay attention to the id's, they are responsible for using the tags in the javascript code.
                    <br/>
                    To begin with the Js, you need to create the variables that store the elements.
                    <CopyBlock
                            text={
                                "           var messages = document.getElementById(\"messages\");\n" +
                                "           var textbox = document.getElementById(\"textbox\");\n"+
                                "           var button = document.getElementById(\"button\");"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    The last step is to add the event listener and execute the creation of the elements of the list.
                    <CopyBlock
                            text={
                                "           button.addEventListener(\"click\", function(){\n" +
                                "               var newMessage = document.createElement(\"li\");\n"+
                                "               newMessage.innerHTML = textbox.value;\n"+
                                "               messages.appendChild(newMessage);\n"+
                                "               textbox.value = \";\"\n"+
                                "            });"
                            }
                            language={"javascript"}
                            theme={atomOneLight}
                            codeBlock
                    />
                    Now, your code creates a "li" for each time you click the button, getting the value of the textbox as the message.
                    <br/>
                    <h4>Exercise</h4>
                    Now is your time to do the CSS, making it look like a real messaging app!
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default MessagingApp;
