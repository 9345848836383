import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class JavascriptIntro extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Introduction and Hello World"}
                       description={"An introduction to Javascript and our first script."}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Introduction"}
                              youtubeURL={"https://youtu.be/_jlPywA4dKs"}
                              localVideoPath={"videos/Javascript/720p/01_IntroductionJavascript.mp4"}
                >
                    <h4>What is Javascript</h4>
                    Javascript is a high-level, dynamically typed, interpreted programming language. It was created by
                    Brendan Eich in 1995. "High-level" means that it’s a language that's quite human-readable, and it
                    abstracts most of the complex details of the machine (computer internals) away.
                    "Dynamically typed" indicates that you don’t have to declare the type of data that a variable
                    will be holding. Javascript can automatically understand the type of data.
                    "Interpreted" means that the code runs directly without needing a compilation step. In contrast,
                    languages such as C++ and Java need to be compiled before they can be run. This property of
                    Javascript means that you can directly run Javascript code from your browser without having to
                    compile it first.
                    It's important to remember that Javascript is different from Java; the similar name was largely
                    a marketing decision rather than a technical one.
                    Today Javascript is a fundamental technology, used virtually everywhere online. It's one of the
                    three core web technologies alongside HTML and CSS, responsible for the programming logic of
                    web pages. Javascript has grown beyond its original domain of web browsers and is now used in a
                    wide variety of environments like servers and even robotics.
                    <h4>Text editor</h4>
                    As text editor for this course we will use Notepad++. You can download it from
                    <a href="https://notepad-plus-plus.org/downloads/"> here</a> if it's not already installed.
                    <br/>
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Hello world"} youtubeURL={"https://youtu.be/nt9ZrbJsLB0"}
                              localVideoPath={"videos/Javascript/720p/03_SetupAndHelloWorld.mp4"}
                >
                    You can run Javascript code in an HTML file in two ways:
                    <ul>
                        <li>By using the <i>script</i> tag and directly writing the Javascript code inside</li>
                        <li>By using the <i>script</i> tag with the <i>src</i> attribute pointing to an external file
                        </li>
                    </ul>
                    This is similar to how we can either embed CSS code inside an HTML file or link to an external CSS
                    file. <br/>
                    For the hello world example we will use the first method. <br/>
                    Inside the <i>body</i> tag of the HTML file, the <i>script</i> tag will look like this:
                    <CopyBlock
                        text={
                            "          <script type=\"application/javascript\">\n" +
                            "               ...\n" +
                            "           </script>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Where instead of the three dots we will write the Javascript code. The <i>type</i> attribute
                    tells the browser that the language we will use inside the script tag is Javascript.
                    <br/>
                    One simple thing that we can do is to write some text to the screen or the document. We can do that
                    by adding the following line inside the script tag:
                    <CopyBlock
                        text={
                            "               document.write(\"Hello world\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The <i>document</i> object is a global object that represents the HTML document. The
                    <i>write</i> method writes some text to the document. <br/>
                    <br/>
                    Additionally, we can also send a popup alert to the user by using the <i>alert</i> method:
                    <CopyBlock
                        text={
                            "               alert(\"Hello world\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    This will display a popup with the text "Hello world". <br/>
                    <br/>
                    Similarly to HTML sometimes you want to write some text in your script, but you don't want it to
                    be interpreted as HTML or Javascript. This type of text is called comment and in Javascript it's
                    added in two ways:
                    <ul>
                        <li>By using the <i>//</i> symbol at the beginning of the line. This will apply only to the line
                        starting with the double slashes and the following line will be considered valid Javascript code
                        </li>
                        <li>By using the <i>/*</i> and <i>*/</i> symbols to surround the comment. This allows to create
                        comments that span more than one line, as all the text between the symbols is considered a comment.
                        </li>
                    </ul>
                    <br/>
                    <br/>
                    If we put together the code from the previous examples, we will get the following valid HTML file
                    containing Javascript code:
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html lang=\"en\">\n" +
                            "     <head>\n" +
                            "          <title>Javascript tutorial</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <script type=\"application/javascript\">\n" +
                            "               //This is a single line comment\n" +
                            "               alert(\"Hello World\");\n" +
                            "               document.write(\"Hello World\");\n" +
                            "               /* \n" +
                            "                   This is a \n" +
                            "                   multiline comment\n" +
                            "               */\n" +
                            "           </script>\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        highlight={"7,8,9,10,11,12,13,14,15"}
                    />
                    <h4>Exercise</h4>
                    Play around with it! Try modifying the text inside the <i>document.write</i> method and see what
                    happens. You can also add a second <i>document.write</i> method call in order to write more text
                    to the document. Add a single-line comment between the two calls.
                    <br/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<!DOCTYPE html>\n" +
                            "<html lang=\"en\">\n" +
                            "     <head>\n" +
                            "          <title>Javascript tutorial</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <script type=\"application/javascript\">\n" +
                            "               document.write(\"Hi everyone\");\n" +
                            "               //This is a comment between the two lines\n" +
                            "               document.write(\", this text is added with Javascript\");\n" +
                            "           </script>\n" +
                            "     </body>\n" +
                            "</html>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default JavascriptIntro;
