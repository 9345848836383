import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";


class ImportsAndBrowserSupport extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Imports and browser support"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Imports"}
                              youtubeURL={"https://youtu.be/kfUsT3-M3_k"}
                              localVideoPath={"videos/CSS/720p/15_Imports.mp4"}
                >
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Browser support"}
                              youtubeURL={"https://youtu.be/B7Y3t2a5mTA"}
                    localVideoPath={"videos/CSS/720p/16_BrowserSupport.mp4"}
                >
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default ImportsAndBrowserSupport;

