import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import SubLesson from "./SubLesson";

// core components

class Lesson extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (<Row>
                <Col className="ml-auto mr-auto mb-4">
                    <Card style={{ borderRadius: 15, backgroundColor: "#f5feff"}}>
                        <CardBody>

                        <CardTitle>
                    <h2 className="title text-center">Introduction</h2>
                            </CardTitle>
                            <h5 className="description mt-3">
                    {this.props.children}
                            </h5>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        );
    }
}

export default Lesson;
