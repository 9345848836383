import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class PasswordChecker extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"PasswordChecker"}
                       description={"Practicing the while loop and conditions"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Building A Password Checker"} youtubeURL={"https://youtu.be/w6nQmCetNuQ"}
                              localVideoPath={""}
                >
                    The first step to creating a password checker is to set the variables: the write one and the response.
                    <CopyBlock
                        text={
                            "               var password = \"wordpass\";\n"+
                            "               var response;"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    /> 
                    Using the while, you can ask for the password until the user writes the right answer.
                    <CopyBlock
                        text={
                            "               while(response != password){\n"+
                            "                   response = window.prompt(\"Enter Password\");\n"+
                            "               }"+
                            "               alert(\"Success\");"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    /> 
                    Also, to make it more functional, you can limit the maximum number of attempts. To do it, first set the variables:                    <CopyBlock
                        text={
                            "               var password = \"wordpass\";\n"+
                            "               var response;\n"+
                            "               var entryCount = 0;\n"+
                            "               var entryLimit = 3;\n"+
                            "               var error = false;"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Then, you must implement the while loop:
                    <CopyBlock
                        text={
                            "               while(response != password && !error){\n"+
                            "                   if(entryCount < entryLimit){\n"+
                            "                       response = window.prompt(\"Enter Password\");\n"+
                            "                       entryCount++;\n"+
                            "                   } else {\n"+
                            "                       error = true;\n"+
                            "                   }\n"+
                            "               }\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    At the end, it is necessary to verify the error condition and print the final message.
                    <CopyBlock
                        text={
                            "               if(error){\n"+
                            "                   alert(\"Too many entries\");\n"+
                            "               } else {\n"+
                            "                   alert(\"Success\");"+
                            "               }"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default PasswordChecker;

                            