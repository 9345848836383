import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CopyBlock} from "react-code-blocks";


class Animations extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Animations"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Animations"}
                              youtubeURL={"https://youtu.be/89dMYqpFcYs"}
                              localVideoPath={"videos/CSS/720p/14_Animations.mp4"}
                >
                    You can easily animate an html element using the <i>keyframe</i> in the css file. First, you must 
                    set the beginning and the end of the animation using the following code:
                    <CopyBlock
                        text={
                            "@keyframes name {\n" +
                            "   from{ }\n" +
                            "   to{ }"+
                            "}"
                        }
                        language={"css"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <ul>
                        <li>After the "keyframe", in the first line, you can name the animation;</li>
                        <li>After the "from", between the curly braces, is the definition of the start of the animation; </li>
                        <li>After the "to", also between the curly braces, is the definition of the final stage of the animation.</li>
                    </ul> 
                    The animation can be made by changing a lot of properties, as color, size, border, opacity and position.
                    <br/>
                    Another option is to define the stages using the percentage, with the same previous attributes:
                    <CopyBlock
                        text={
                            "@keyframes name {\n" +
                            "   0%{ }\n" +
                            "   25%{ }\n" +
                            "   50%{ }\n" +
                            "   75%{ }\n" +
                            "   100%{ }"+
                            "}"
                        }
                        language={"css"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    After created, you can add it to the html tag using the class reference,
                    and using the propriety <i>animation-name</i>
                    <CopyBlock
                        text={
                            ".class {\n" +
                            "   animation-name: name;\n" +
                            "}"
                        }
                        language={"css"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Also, you can change some others attributes, as:
                    <ul>
                        <li>The time it will take: <i>animation-duration</i> </li>
                        <li>The break before it starts: <i>animation-delay</i></li>
                        <li>The number it will repeat: <i>animation-iteration-count</i></li>
                        <li>How the animation should be played (normal, reverse, alternate or alternate-reverse): animation-direction</li>
                    </ul>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Animations;

