import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";
import {atomOneLight, CopyBlock} from "react-code-blocks";


class IDsAndClasses extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"IDs and classes"}
                       description={""}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"IDs and classes"}
                              youtubeURL={"https://youtu.be/4C_-y9dIc54"}
                              localVideoPath={"videos/CSS/720p/11_IDsAndClasses.mp4"}
                >
                    There are some names you can give as properties to easily reference an HTML element or group in
                    your CSS document. This allows you to easily change the style of a specific element or group of
                    elements all at once.
                    The main ones are:
                    <i>Id</i> is used by just one single element and has priority over the class (when they configure
                               the same attributes);
                    <i>Class</i> is used by a group of components on the page.
                    Each tag can receive more than one class, but just one id.
                    Here is an example of how they work:
                    <br/>
                    <b>HTML file</b>
                    <CopyBlock
                        text={
                            "<p class=\"paragraphG\">This is a grey paragraph</p>\n" +
                            "<p class=\"paragraphG\">This is another grey paragraph</p>\n" +
                            "<p id=\"paragraphB\" class=\"paragraphG\">This is a blue paragraph</p>\n"
                        }
                        language={"html"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <b>CSS file</b>
                    <CopyBlock
                        text={
                            ".paragraphG {" +
                            "   color:\"grey\"; }" +
                            "" +
                            "#paragraphB {" +
                            "   color:\"blue\"; }"
                        }
                        language={"css"}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    Here is how is displayed:
                    <p style={{color: "grey"}}>This is a grey paragraph</p>
                    <p style={{color: "grey"}}>This is another grey paragraph</p>
                    <p style={{color: "blue"}}>This is a blue paragraph</p>
                    <br/>
                    <b>Pay attention to the last paragraph: it has the class that defines the color grey, but the <i>id</i>
                       (for which the color blue is defined in the CSS) has priority. </b>
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default IDsAndClasses;

