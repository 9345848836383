import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class DivsSpansAndMetaTags extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Divs and spans, Meta tags"}
                       description={"Organize HTML content in block or in-line elements like divs and spans and " +
                       "improve the visibility and information of your website with meta tags."}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Divs and Spans"} youtubeURL={"https://youtu.be/lyVwVgiRNIU"}
                              localVideoPath={"videos/HTML/720p/13_DivsAndSpans.mp4"}
                >
                    Divs and spans are HTML tags that can be put around other HTML tags, which is why they are called
                    container tags. They are useful to divide and organize the code and change how the page is
                    displayed. <br/>
                    The only difference between them is that the <i>div</i> tag creates a <b>block display</b>, that
                    fills all the line independent of the element inside, while the <i>span</i> tag does a
                    <b> line display</b> and takes just the space of the content. In other words, one <i>div </i>
                    element always takes up a full line and more than one <i>div</i> element cannot stay on the same
                    line, while more than one <i>span</i> element can live on the same line.<br/>
                    These two elements take a particular role when working together with CSS and JavaScript, about
                    which we will talk more in the next lesson.
                    <br/>
                    Here is an example:
                    <CopyBlock
                        text={
                            "<span>Span:</span>\n" +
                            "<span> display in line</span>\n" +
                            "<br/>\n" +
                            "<div>Div:</div>\n" +
                            "<div> display in block</div>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    And that's how it looks like, as you can see the two spans are on the same line, while the two
                    divs are displayed on separate lines: <br/>
                    <hr/>
                    <span>Span:</span>
                    <span> display in line</span>
                    <br/>
                    <br/>
                    <div>Div:</div>
                    <div> display in block</div>
                    <hr/>
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Meta tags"} youtubeURL={"https://youtu.be/Luo_wCcWAaw"}
                              localVideoPath={"videos/HTML/720p/16_MetaTags.mp4"}
                >
                    We have already talked about meta tags in the first lesson, as it's good procedure to add them to
                    every website. The information they carry will not be displayed in the website, but rather they
                    carry information <b>about</b> the website, which is why they are called <b>meta</b> tags. <br/>
                    Each meta tag can have one or more attributes. Attributes are additional information passed
                    to the tag inside the tag and are in the form of key-value pairs, for example <i>name="value"</i>.
                    <br/>
                    Meta tags sit in the header part of your page, for example:
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website Title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>My Website</h1>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        highlight={"3,4"}
                        codeBlock
                    />
                    This HTML code contains two meta tags:
                    <CopyBlock
                        text={
                            "          <meta charset=\"UTF-8\">"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={3}
                    />
                    The first one declares the charset that our website will use. The charset is another way of saying
                    the set of characters or character encoding. It will mostly always be UTF-8 for our applications.
                    <CopyBlock
                        text={
                            "          <meta name=\"description\" content=\"This is a great website\">"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                        startingLineNumber={4}
                    />
                    The second one is a meta tag that gives a description to our website. Again, this description will
                    not be visible to the end user, but is very useful for search engines like Google in order to
                    understand your page. A common description uses around 140-160 characters.
                    <br/><br/>
                    Other popular meta tags that you may see in websites:
                    <CopyBlock
                        text={
                            "          <meta name=\"author\" content=\"Name of the author of the website\">"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The author tag allows to declare who was the author(s) of the website.
                    <CopyBlock
                        text={
                            "          <meta name=\"keywords\" content=\"Keyword, my website\">"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    The keywords metatag allow you to declare keywords that describe the website you are building. These
                    are also useful for Google.
                    <CopyBlock
                        text={
                            "          <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    This metatag allows you to improve the way your website is displayed on mobile and tells the
                    browser to zoom over the website to fit it to the small screen of mobile.
                    <h4>Exercise</h4>
                    Write a simple HTML file with the meta tags which would make sense if you were building a webiste
                    for your school.
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a website that describes my school.\">\n" +
                            "          <meta name=\"author\" content=\"John Doe\">\n" +
                            "          <meta name=\"keywords\" content=\"School, learning, courses, fun\">\n" +
                            "          <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
                            "          <title>My school's Website</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>My school's Website</h1>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default DivsSpansAndMetaTags;
