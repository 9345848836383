import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class BasicTags extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Basic tags"}
                       description={"Some of the basic tags to format text and order the page."}>
                <SubLessonJSX lessonIndex={1} subLessonIndex={0}
                              title={"Basic tags"} youtubeURL={"https://youtu.be/YTxrjJS6rpE"}
                              localVideoPath={"videos/HTML/720p/04_BasicTags.mp4"}
                >
                    After watching the video you can directly copy the code into the <i>index.html</i> file.
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website Title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>My Website</h1>\n" +
                            "          <hr/>\n" +
                            "          <p>\n" +
                            "               <big>This</big> is <small>my</small> <b><i>paragraph</i></b>\n" +
                            "          </p>\n" +
                            "\n" +
                            "          <p>\n" +
                            "               10<sup>2</sup>\n" +
                            "          </p>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    <br/>
                    We are going to go over it line by line:
                    <ul>
                        <li> <b><big>Meta tags</big></b>
                            <CopyBlock
                                text={
                                    "          <meta charset=\"UTF-8\">\n" +
                                    "          <meta name=\"description\" content=\"This is a great website\">\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={3}
                                codeBlock
                            />

                            These are called <b>meta tags</b>, as they tell something about the website, rather than
                            being about formatting text. You can also observe that they are not container tags. This
                            means that they do not have any text in between the opening and the closing tag, but are
                            constituted only of one single opening <b>and</b> closing tag, terminated with a forward
                            slash at the end, just before the bigger than symbol <b>/</b>. <br/>
                            Moreover we can add information inside a tag like so: <b>name="description"</b>
                            or <b>charset="UTF8"</b>.
                            These are called attributes, and they provide input into an HTML tag
                            with more information about what it’s doing. The part that we can specify is instead between
                            double quotes. It’s good practice to give your website a description and declare
                            the charset, but don't worry too much about these two tags for now. They helped us to
                            introduce the concept of attributes.
                        </li>
                        <li> <b><big>Headers</big></b>
                            <CopyBlock
                                text={
                                    "<h1>My Website</h1>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={8}
                                codeBlock
                            />
                            The header tag helps to give a title to your paragraphs. It basically makes the
                            text bigger and bold. There are 6 levels of headers that go from <b>&lt;h1&gt;</b> ...
                            to ... <b>&lt;h6&gt;</b>. With increasing numbers the importance and size of the title
                            decreases. Usually these are used in sequence, starting from 1, which is the biggest.
                            They are useful to structure your text into titled and nested paragraphs.
                        </li>
                        <li>
                            <b><big>Horizontal line and line break</big></b>
                            <CopyBlock
                                text={
                                    "<hr/>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={9}
                                codeBlock
                            />
                            This tag, closed immediately with a forward slash, just draws an horizontal line in
                            the page. it's useful for separating parts of the websites. In the video you also saw
                            Mike use the <b>new line</b> or line break tag <b>&lt;br/&gt;</b>. This just inserts
                            a new line in your formatted text. In fact, HTML is insensible to new lines inserted in
                            the code. They are not reflected in the final rendering of the text, unless they are
                            explicitly declared with a newline <b>&lt;br/&gt;</b> tag.
                        </li>
                        <li>
                            <b><big>Paragraphs</big></b>
                            <CopyBlock
                                text={
                                    "<p>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={10}
                                codeBlock
                            />
                            <CopyBlock
                                text={
                                    "</p>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={12}
                                codeBlock
                            />
                            This tag is used to encapsulates text that refers to the same paragraph. It adds some
                            space from the text above and the one below. It's good practice to encapsulate a block
                            of text with related sentences into a single paragraph.
                        </li>
                        <li>
                            <b><big>Text formatters</big></b>
                            <CopyBlock
                                text={
                                    "<big>This</big> is <small>my</small> <b><i>paragraph</i></b>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={11}
                                codeBlock
                            />
                            <CopyBlock
                                text={
                                    "10<sup>2</sup>\n"
                                }
                                language={"html"}
                                theme={atomOneLight}
                                startingLineNumber={15}
                                codeBlock
                            />
                            Some tags help you to format the text:
                            <ul>
                                <li><b>&lt;big&gt;</b> makes the text <big>bigger</big></li>
                                <li><b>&lt;small&gt;</b> makes the text <small>smaller</small></li>
                                <li><b>&lt;b&gt;</b> makes the text <b>bold</b></li>
                                <li><b>&lt;i&gt;</b> makes the text <i>italic</i></li>
                                <li><b>&lt;sup&gt;</b> makes the text <sup>superscripted</sup></li>
                                <li><b>&lt;sub&gt;</b> makes the text <sub>subscripted</sub></li>
                            </ul>
                        </li>
                    </ul>
                    <h4>Exercise</h4>
                    Wow, that was a lot to take. Take your time to let everything sink in. The best way to
                    understand things is to use them. For example try changing the html file:
                    <ul>
                        <li>Exchange the horizontal one tag (<b>&lt;hr/&gt;</b>) with a new line tag</li>
                        <li>Insert two sub-titles under the <i>My website</i> header</li>
                        <li>Under those two sub-titles insert one paragraph each, one with a bold word
                            and one with an italic word</li>
                    </ul>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<html>\n" +
                            "     <head>\n" +
                            "          <meta charset=\"UTF-8\">\n" +
                            "          <meta name=\"description\" content=\"This is a great website\">\n" +
                            "          <title>My Website Title</title>\n" +
                            "     </head>\n" +
                            "     <body>\n" +
                            "          <h1>My Website</h1>\n" +
                            "          <br/>\n" +
                            "          <h2>First sub-paragraph</h2>\n" +
                            "               <p>\n" +
                            "                    <b>Bold word</b>\n" +
                            "               </p>\n" +
                            "          <h2>Second sub-paragraph</h2>\n" +
                            "               <p>\n" +
                            "                    <i>Italic word</i>\n" +
                            "               </p>\n" +
                            "     </body>\n" +
                            "</html>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default BasicTags;
