import React from "react";

// reactstrap components
import VideoLocalOrYoutube from "./VideoLocalOrYoutube";

// core components

class SubLesson extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (<>
                <h3 className="title">
                    Lesson {this.props.lessonIndex+1}.{this.props.subLessonIndex+1} - {this.props.title}
                </h3>
                <VideoLocalOrYoutube
                    localPath={this.props.localVideoPath}
                    youtubeURL={this.props.youtubeURL}
                />
                <h5 className="description mt-3">
                    {this.props.children}
                </h5>
            </>
        );
    }
}

export default SubLesson;
