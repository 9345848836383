import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class Variables extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Variables"}
                        description={""}>   
                        {/* TODO: descripton */}
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Variables and data types"} youtubeURL={"https://youtu.be/YptrAq5O-jQ"}
                              localVideoPath={""}
                >
                    A variable is a container that can store information (data) and is declared using the keyword <i>var</i>.
                    The main types are:
                    <ul>
                        <li>String: a group of characters enclosed in single quotes or double quotes;</li>
                        <li>Numeric: numbers with or without the floating point;</li>
                        <li>Boolean: true or false.</li>
                    </ul>
                    Here is an example of how you can create, change the value and print a variable on your code:
                    <CopyBlock
                        text={
                            "               var numberOfStudents;\n" +
                            "               numberOfStudents = 10;\n" +
                            "               document.write(\"There are \" + numberOfStudents + \" students in this class.\");\n"
                        }
                        language={"javascript"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Strings"} youtubeURL={"https://youtu.be/3JJyYmkXMSA"}
                              localVideoPath={""}
                >
                    As you've learned, a string is a type of variable that contains text-based data. Working with
                    strings avails a wealth of information and numerous manipulative methods. Here are some examples
                    of useful string methods using a string variable named "text":
                    <ul>
                        <li>text.length: to get the number of characters in the string;</li>
                        <li>text.charAt(0): to get the character at the specified position;</li>
                        <li>text.indexOf("ish"): to find the position of a specific substring within the string;</li>
                        <li>text.substring(2): to get a new string starting from the specified index and ending at the
                            end of the string;</li>
                        <li>text.substring(1, 3): to get a new string between the specified indices;</li>
                        <li>text.toUpperCase(): to convert all characters in the string to uppercase;</li>
                        <li>text.toLowerCase(): to convert all characters in the string to lowercase.</li>
                    </ul>
                    These values can be displayed using <i>document.write</i> or stored in another variable for
                    later use.
                   <br/>
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={2}
                              title={"Math and numbers"} youtubeURL={"https://youtu.be/1qm_SSLunkw"}
                              localVideoPath={""}
                >
                    With data stored in a variable, you can perform basic arithmetic operations such as addition (+),
                    subtraction (-), multiplication (*), division (/), exponentiation (**), and modulus operation (%).
                    <br/>
                    If you need to add a value to a variable and store it back in the same variable, you don't have to
                    write it out the long way (number = number + 5). Instead, you can simply do: <i>number += 5;</i>.
                    The same principle applies for subtraction (-=), multiplication (*=), and division (/=).
                    <br/>
                    Moreover, if you need to increment a variable by one, you can use: <i>number++;</i>.
                    <br/>
                    Additionally, there are some handy methods available in JavaScript through the Math object. Below
                    are few examples:
                    <ul>
                        <li>Math.abs(-15) : returns the absolute value (returns 15);</li>
                        <li>Math.max(5, -3) : determines the greater number (returns 5);</li>
                        <li>Math.min(15, 182) : figures out the smaller number (returns 15);</li>
                        <li>Math.round(1.85): rounds the number (returns 2);</li>
                        <li>Math.pow(5, 2) : raises the first number to the power of the second (returns 25);</li>
                        <li>Math.sqrt(25) : returns the square root of a number (returns 5);</li>
                        <li>Math.random() : generates a random number between 0 and 1 (returns a decimal number).</li>
                    </ul>
                    These values can be displayed using <i>document.write</i> or saved in another variable for later use.
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default Variables;
