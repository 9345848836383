import {atomOneLight, CopyBlock} from "react-code-blocks";
import LessonJSX from "../../../components/Courses/LessonJSX";
import SubLessonJSX from "../../../components/Courses/SubLessonJSX";
import React from "react";

class CssIntro extends React.Component {
    render() {
        return (
            <LessonJSX lessonIndex={this.props.lessonIndex} title={"Introduction and basic inline styling"}
                       description={"Introduction to CSS and some basic inline styling"}>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={0}
                              title={"Introduction"}
                              youtubeURL={"https://youtu.be/WZ2uqGkHoR0"}
                              localVideoPath={"videos/CSS/720p/01_Introduction.mp4"}
                >
                    CSS and HTML are tightly coupled together and CSS enriches HTML with fonts, styles, colours and sizes.
                    This course builds on top of the HTML course and knowledge. <br/>
                    As usual, as text editor, we recommend to use Atom, as it's very simple. Please check now
                    if it's installed on your machine, if not download and install it from
                    <a href="https://atom.io/"> here</a>.
                </SubLessonJSX>
                <SubLessonJSX lessonIndex={this.props.lessonIndex} subLessonIndex={1}
                              title={"Basic inline styling"}
                              youtubeURL={"https://youtu.be/jH_WY-sQ8Lg"}
                              localVideoPath={"videos/CSS/720p/02_BasicInlineStyling.mp4"}
                >
                    Inline styling is a type of style that you can use inside the HTML tags, in the same HTML file.
                    You can do that by using the property <i>style=""</i>, where inside the the quotes you can insert
                    CSS attributes and values. A CSS attribute is a particular characteristic of the HTML element that
                    you want to modify, for example colour, size etc; a CSS value is the value that you want to give
                    to that characteristic, for example red, blue etc. You can imagine it a key-value pair. <br/>
                    For example:
                    <CopyBlock
                        text={
                            "<p style=\"color: blue;\">Blue text</p>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <p style={{"color": "blue"}}>Blue text</p>
                    <br/>
                    Common CSS attributes can be:
                    <ul>
                        <li><i>color</i>, which decides the color of the text and for which values can
                            be <i>blue</i>, <i>red</i> etc</li>
                        <li><i>background-color</i>, which decides the color of the background</li>
                        <li><i>font-size</i>, which decides the size of the text and for which for which values can be
                            expressed in pixels, for example <i>20px</i></li>
                        <li><i>border</i>, which shows a border around the element and takes 3 keys: the size in pixels,
                        the type or style of border, the color. For example: <i>border: 1px solid black</i></li>
                        <li><i>width</i> or <i>height</i>, which decides the size of the element and can be expressed
                        in pixels (<i>200px</i>), but also in percentage relative to the screen size (<i>80%</i>).</li>
                        <li><i>max-width</i> or <i>max-height</i>, which decides the maximum size of the element,
                            can be expressed in pixels (<i>200px</i>) and it's normally used in combination with
                            the <i>width</i> or <i>height</i> attribute.</li>
                        <li>Many more can be found at: <a href="https://www.w3schools.com/cssref/">W3schools CSS reference page</a>
                        </li>
                    </ul>
                    We'll see many of these attributes in more details in the following lessons. Bear in mind that
                    attributes can be combined together, for example <i>style="color: blue; font-size: 20px"</i>.
                    <br/>
                    Notice that the attributes are inherited by the HTML tags that are child tags. Child tags can
                    also re-define already defined attributes and override their values. For example
                    in the following code the body has the <i>color: blue;</i> attribute, but then inside the header
                    this is re-defined as <i>color: red;</i>. Additionally, the paragraph inside the main tag inherits
                    the color blue from the body tag.
                    <br/>
                    <CopyBlock
                        text={
                            "<body style=\"color: blue;\">\n" +
                            "     <header>\n" +
                            "          <h1 style=\"color: red;\">CSS Tutorial</h1>\n" +
                            "     </header>\n" +
                            "\n" +
                            "     <main>\n" +
                            "          <p>This is a paragraph</p>\n" +
                            "     </main>\n" +
                            "</body>\n"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                    Will be displayed as:
                    <hr/>
                    <span style={{"color": "blue"}}>
                    <h3 style={{"color": "red"}}>CSS Tutorial</h3>
                    <p>This is a paragraph</p>
                        </span>
                    <hr/>
                    <h4>Exercise</h4>
                    Create an HTML page where the default text color is blue, the header contains an <i>h1</i> tag with
                    color red and and yellow background. Additionally, the main tag should contain
                    a paragraph with font size 12 pixels, with a green border of size 2 pixels, that occupies
                    70% of the page, but never more than 600 pixels. <br/>
                    It should look like: <br/>
                    <hr/>
                    <span style={{"color": "blue"}}>
                        <h3 style={{"color": "red", "backgroundColor": "yellow"}}>This is an h1 title</h3>
                        <p style={{"border": "2px solid green", "width": "70%", "maxWidth": "500px"}}>
                            This is a paragraph
                        </p>
                    </span>
                    <hr/>
                    <h4>Solution</h4>
                    <CopyBlock
                        text={
                            "<body style=\"color: blue;\">\n" +
                            "     <header>\n" +
                            "          <h1 style=\"color: red; background-color: yellow\">CSS Tutorial</h1>\n" +
                            "     </header>\n" +
                            "\n" +
                            "     <main>\n" +
                            "          <p style=\"border: 2px solid green; width: 70%; max-width: 600px\">This is a paragraph</p>\n" +
                            "     </main>\n" +
                            "</body>"
                        }
                        language={"html"}
                        theme={atomOneLight}
                        codeBlock
                    />
                </SubLessonJSX>
            </LessonJSX>
        );
    }
}

export default CssIntro;
